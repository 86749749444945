import React from 'react';
import './InfosGenerales.css';
import { Support } from '../../data/objects/Support';
import { Parution } from '../../data/objects/Parution';
import { sortParutionsAsc, countParutionsPerYear, getCurrentNextParution } from '../../utility/ParutionUtility';

interface InfoGeneralesProps {
    support: Support | undefined;
    parutions: Parution[];
}

export const InfoGenerales = (props: InfoGeneralesProps) => {
    const [nbParutions, setNbParutions]: [number, (nbParutions: number) => void] = React.useState(0);
    const [nextParutionDate, setNextParutionDate]: [string, (nextParutionDate: string) => void] = React.useState("n.c");

    React.useEffect(() => {
        sortParutionsAsc(props.parutions);
        setNbParutions((countParutionsPerYear(props.parutions)));
        let nextpar = getCurrentNextParution(props.parutions).nextParuDate;
        setNextParutionDate(nextpar ? nextpar.toLocaleDateString() : "n.c");
    }, [props.parutions]);

    return (
        <div className="infogeneralesDiv">
            <h2>{props.support?.Name}</h2>
            <div className="infogeneralestab">
                <table>
                    <tbody>
                        <tr>
                            <th>Périodicité</th>
                            <td>{props.support?.Periodicity ? props.support.Periodicity.charAt(0).toUpperCase() + props.support.Periodicity.substring(1).toLowerCase() : "n.c"}</td>
                        </tr>
                        <tr>
                            <th>Numéros par an</th>
                            <td>{nbParutions === 0 ? "n.c" : nbParutions}</td>
                        </tr>
                        <tr>
                            <th>Jour de sortie</th>
                            <td>{nextParutionDate}</td>
                        </tr>
                        <tr>
                            <th>Régie</th>
                            <td>{props.support?.Society ? props.support.Society : "n.c"}</td>
                        </tr>
                        <tr>
                            <th>Marque</th>
                            <td>{props.support?.Brand ? props.support.Brand : "n.c"}</td>
                        </tr>
                        <tr>
                            <th>Site internet</th>
                            <td>{props.support?.Website ? <a href={props.support.Website}>{props.support.Website}</a> : "n.c"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}