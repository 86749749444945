import { APIProvider } from './APIProvider';
import { NodeBody } from '../Body/NodeBody';
import { ApiConstants } from '../../utility/Constants';
import { Parution } from '../objects/Parution';
import { GetParutionsMLResponse } from '../ApiResponse/parution/GetParutionsMLResponse';
import { GetParutionsNodeResponse } from '../ApiResponse/parution/GetParutionsNodeResponse';
import { ParutionsMLMap } from '../ResponseToItemMap/parution/ParutionsMLMap';
import { ParutionsNodeMap } from '../ResponseToItemMap/parution/ParutionsNodeMap';

export class ParutionAPIProvider extends APIProvider {
    private mlMap: ParutionsMLMap;
    private nodeMap: ParutionsNodeMap;
    
    public constructor() {
        super();

        this.mlMap = new ParutionsMLMap();
        this.nodeMap = new ParutionsNodeMap();
    }

    public getParutionsBySupportCodeTsm(supportCode: string): Promise<Parution[] | undefined> {
        return (this.getMultiple<GetParutionsMLResponse, Parution>(ApiConstants.APIParutionEndpoint + supportCode, this.mlMap));
    }

    public getParutionsAdwBySupportId(supportId: string): Promise<Parution[] | undefined> {
        const body: NodeBody = new NodeBody(ApiConstants.APINodeSupportParutionsEndpoint + supportId)
        return (this.postMultiple<GetParutionsNodeResponse, Parution>(ApiConstants.APINodeEndpoint, body, this.nodeMap, false));
    }
}