import { IAPIItem } from './IAPIItem';
import { AudienceCible } from './AudienceCible';

export class AudienceGroupe implements IAPIItem {
    public ID: number;
    public Name: string;
    public AudienceByCibles: AudienceCible[];

    public constructor(ID: number, Name: string, AudienceByCibles: AudienceCible[]) {
        this.ID = ID;
        this.Name = Name;
        this.AudienceByCibles = AudienceByCibles;
    }
}