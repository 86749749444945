/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { QuestionIdsConstantsType } from '../../utility/Constants';
import { XYPlot, HorizontalBarSeries, LabelSeries, HorizontalBarSeriesPoint, LabelSeriesPoint } from 'react-vis';
import { AudienceGroupe } from '../../data/objects/AudienceGroupe';
import './VerticalGraph.css';

interface VerticalGraphProps {
    supportId: number;
    waveId: number;
    questionIds: QuestionIdsConstantsType[];
    labelColor?: string;
    height?: number;
    width?: number;
    titleHidden: boolean;
}

export const VerticalGraph = (props: VerticalGraphProps) => {
    const [maxGrp, setMaxGrp] = React.useState(16);
    const [maxAffi, setMaxAffi] = React.useState(290);
    const [dataTabAffinite, setDataTabAffinite] = React.useState<HorizontalBarSeriesPoint[]>([]);
    const [dataQuestion, setDataQuestion] = React.useState<LabelSeriesPoint[]>([]);
    const [dataLabels, setDataLabels] = React.useState<LabelSeriesPoint[]>([]);
    const [dataLabelAffinite, setDataLabelAffinite] = React.useState<LabelSeriesPoint[]>([]);
    const [dataTab, setDataTab] = React.useState<HorizontalBarSeriesPoint[]>([]);
    const [width, setWidth] = React.useState(340);
    const [height, setHeight] = React.useState(277);
    const [labelColor, setLabelColor] = React.useState("white");
    const [groupeAudsForGraph, setGroupeAudsForGraph] = React.useState<AudienceGroupe[]>([]);

    const groupeAuds = useSelector((state: RootState) => state.audiences.groupeAuds);
    const theme = useSelector((state: RootState) => state.theme.colors);

    React.useEffect(() => {
        if (props.width)
            setWidth(props.width);
        
        if (props.height)
            setHeight(props.height);
        
        if (props.labelColor)
            setLabelColor(props.labelColor);
    }, [props.width, props.height, props.labelColor])

    React.useEffect(() => {
        if (groupeAudsForGraph.length > 0) {
            let ldataTabAffinite: HorizontalBarSeriesPoint[] = [];
            let ldataQuestion: LabelSeriesPoint[] = [];
            let ldataLabel: LabelSeriesPoint[] = [];
            let ldataLabelAffinite: LabelSeriesPoint[] = [];
            let ldataTab: HorizontalBarSeriesPoint[] = [];
            let tmpMaxGrp = 0;
            let tmpMaxAffi = 0;

            groupeAudsForGraph.forEach((audGroupe) => {
                audGroupe.AudienceByCibles.forEach((audCible) => {
                    if (audCible.Grp > tmpMaxGrp)
                        tmpMaxGrp = audCible.Grp;
                    if (audCible.Affinite > tmpMaxAffi)
                        tmpMaxAffi = audCible.Affinite;
                });
            });

            tmpMaxGrp *= 1.50;
            tmpMaxAffi *= 1.50;

            let idxGroup: number = 0;
            let idxTarget: number = 0;
            let tmpGroupeAudsForGraph = [...groupeAudsForGraph];
            tmpGroupeAudsForGraph = tmpGroupeAudsForGraph.reverse();
            tmpGroupeAudsForGraph.forEach((audGroupe) => {
                audGroupe.AudienceByCibles.forEach((audCible) => {
                    let valGrp = audCible.Grp;
                    let valAffi = audCible.Affinite;

                    let dataElement: HorizontalBarSeriesPoint = {
                        x: valGrp / 2,
                        y: idxTarget,
                        color: idxGroup % 2 === 0 ? theme?.ColorGraph : theme?.ColorLastGraph
                    };

                    let dataLab: LabelSeriesPoint = {
                        x: valGrp / 2,
                        xOffset: 15,
                        y: idxTarget,
                        label: audCible.Grp.toFixed(2),
                        style: { fontSize: 11, fill: labelColor }
                    };

                    let dataLabAffi: LabelSeriesPoint = {
                        x: valAffi * -1,
                        xOffset: -12,
                        y: idxTarget,
                        label: audCible.Affinite.toFixed(0),
                        style: { fontSize: 11, fill: labelColor, textAnchor: 'middle' }
                    };

                    let dataElemAffi: HorizontalBarSeriesPoint = {
                        x: valAffi * -1 - tmpMaxAffi,
                        color: idxGroup % 2 === 0 ? theme?.ColorGraph : theme?.ColorLastGraph,
                        y: idxTarget
                    };

                    let dataQues: LabelSeriesPoint = {
                        x: (tmpMaxGrp * 1.20) / 2,
                        y: idxTarget,
                        label: audCible.Name,
                        style: { fontSize: 11, fill: labelColor, textAnchor: 'middle' }
                    }

                    if (tmpGroupeAudsForGraph.length === 1) {
                        dataElement.color = theme?.ColorLastGraph;
                        dataElemAffi.color = theme?.ColorLastGraph;
                    }

                    ldataQuestion.push(dataQues);
                    ldataLabel.push(dataLab);
                    ldataLabelAffinite.push(dataLabAffi);
                    ldataTab.push(dataElement);
                    ldataTabAffinite.push(dataElemAffi);
                    idxTarget++;
                });
                idxGroup++;
            });
            setMaxGrp(tmpMaxGrp);
            setMaxAffi(tmpMaxAffi);
            setDataQuestion(ldataQuestion);
            setDataLabels(ldataLabel);
            setDataLabelAffinite(ldataLabelAffinite);
            setDataTab(ldataTab);
            setDataTabAffinite(ldataTabAffinite);
        }
    }, [groupeAudsForGraph])

    React.useEffect(() => {
        if (groupeAuds.length > 0) {
            let tmpGroupeAudsForGraph: AudienceGroupe[] = [];

            props.questionIds.forEach(questionId => {
                groupeAuds.forEach(groupeAud => {
                    if (groupeAud.ID === questionId)
                        tmpGroupeAudsForGraph.push(groupeAud);
                });
            });

            setGroupeAudsForGraph(tmpGroupeAudsForGraph);
        }
    }, [groupeAuds]);

    return (
        <div style={{ marginTop: '-20px' }}>
            {props.titleHidden === false ? <p style={{ textAlign: 'center' }}>Audience</p> : null}
            <div className={props.titleHidden === true ? "legendLeftNoTitle" : "legendLeft"}>Aud</div>
            <div className={props.titleHidden === true ? "legendRightNoTitle" : "legendRight"}>Affinité</div>
            <div style={{ float: 'left', marginLeft: '-35px', marginTop: '15px' }}>
                <XYPlot className="verticalbarseriesexample" width={width} height={height} xDomain={[0, maxGrp + maxGrp * 0.10]}>
                    <HorizontalBarSeries
                        colorType="literal"
                        color={theme?.ColorLastGraph}
                        data={dataTab}
                        barWidth={0.85}
                    />
                    <LabelSeries labelAnchorY='middle' labelAnchorX='middle' data={dataLabels} />
                    <LabelSeries labelAnchorY='middle' labelAnchorX='middle' data={dataQuestion} />
                </XYPlot>
            </div>
            <div style={{ float: 'left', marginLeft: ((width / 2 - 20) * - 1).toString() + "px", marginTop: '15px' }}>
                <XYPlot className="verticalbarseriesexample" width={width / 2} height={height} xDomain={[maxAffi * -1, 0]} >
                    <HorizontalBarSeries
                        xDomain={[maxAffi * -2, maxAffi * -1]}
                        colorType="literal"
                        color={theme?.ColorLastGraph}
                        data={dataTabAffinite}
                        barWidth={0.85}
                    />
                    <LabelSeries labelAnchorX='start' labelAnchorY='middle' data={dataLabelAffinite} />
                </XYPlot>
            </div>
        </div>
    );
}