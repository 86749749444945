import { SupportState, GET_SUPPORT, GET_SOCIETE, SupportActionTypes } from '../types/support.types';

const initialState: SupportState = {
    support: undefined,
    societe: undefined
};

export function supportReducer(state = initialState, action: SupportActionTypes): SupportState {
    switch (action.type) {
        case GET_SUPPORT:
            return ({
                support: action.payload,
                societe: state.societe
            });
        case GET_SOCIETE:
            return ({
                support: state.support,
                societe: action.payload
            });
        default:
            return (state);
    }
}