/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { sortParutionsAsc, getCurrentNextParution, CurrentParutionInfos } from '../../utility/ParutionUtility';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { Scrollbars } from 'react-custom-scrollbars';

import ReactTable from "react-table";
import "react-table/react-table.css";

import "./VerticalTimeline.css";
import "./VerticalTimelineElement.css";
import "./timeline.css";
import "./parutions.css";
import { NextParution } from './NextParution';
import { Support } from '../../data/objects/Support';

function FormatDate(dateValue: Date) : string {
    let formatedDate = dateValue.toLocaleDateString();

    return formatedDate;
}

function TwoDigit(n) : string {
    return n > 9 ? "" + n : "0" + n;
}

const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
];

const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
];

interface ParutionsTableProps {
    support: Support
}

export const ParutionsTable = (props: ParutionsTableProps) => {
    const [nextParutionDate, setNextParutionDate] = React.useState("n.c");
    const [parutionInfos, setParutionInfos] = React.useState<CurrentParutionInfos | null>(null);
    const [currentParu, setCurrentParu] = React.useState<string>("");
    const [tab, setTab] = React.useState<JSX.Element[]>([]);
    const [cols, setCols] = React.useState<any>([]);
    const [currentParuIdx, setCurrentParuIdx] = React.useState<number>(0);
    const scrollRef = React.useRef<Scrollbars>();

    const parutions = useSelector((state: RootState) => state.parutions.parutions);
    const theme = useSelector((state: RootState) => state.theme.colors);    

    function rowFormat(row, date) {

        let dayStr = days[date.getDay()];
        let monthStr = months[date.getMonth()];
        let dayOfMonthStr = TwoDigit(date.getDate());
        let year = date.getFullYear();
    
        return (<div className={getClassname(row)}>
            <div className="cellDayOfWeek">{dayStr}</div>
            <div className="cellDayOfMonth">{dayOfMonthStr}</div>
            <div className="cellMonth">{monthStr}</div>
            <div className="cellYear">{year}</div>
        </div>);
    };
    
    const getClassname = (row): string  => {
        let className = "basicParutionCell";
        if (FormatDate(row.original.releaseDate) === currentParu)
            className = "currentParutionCell";
        return className;
    };

    React.useLayoutEffect(() => {
        adjustCurrentParu();
    });

    React.useEffect(() => {
            MLCols();
    }, [currentParu])

    React.useEffect(() => {
        if (parutions) {
            sortParutionsAsc(parutions);
            let nextpar = getCurrentNextParution(parutions);
            setCurrentParu(FormatDate(nextpar.currentParuDate ? nextpar.currentParuDate : new Date()))
            setTab(generateTabEvents(nextpar));
            setNextParutionDate(nextpar.nextParuDate ? nextpar.nextParuDate.toLocaleDateString() : "n.c");
            setParutionInfos(nextpar);
            setCurrentParuIdx(Math.floor(nextpar.currentParuIdx / 15));
        }
    }, [parutions]);

    const MLCols = () => {
        let tabColumns = [{
            Header: "N",
            accessor: "numero",
            width: 50
        }, {
            Header: "date de parution",
            Cell: (row) => { return rowFormat(row, row.original.releaseDate) }
        }, {
            Header: "date de début",
            Cell: (row) => { return rowFormat(row, row.original.startDate) }
        }, {
            Header: "date de fin",
            Cell: (row) => { return rowFormat(row, row.original.endDate) }
        }, {
            Header: "Contenu",
            accessor: "contenu"
        }]

        setCols(tabColumns);
    }

    const AdwCols = () => {
        let tabColumns = [{
            id: "releaseDate",
            Header: "date de parution",
            Cell: (row) => { return rowFormat(row, row.original.releaseDate) },
            accessor: val => val,
            sortMethod: (a,b) => a.releaseDate - b.releaseDate
        }, {
            id: "commercialDate",
            Header: "bouclage commercial",
            Cell: (row) => { return rowFormat(row, row.original.commercialDate) },
            accessor: val => val,
            sortMethod: (a,b) => a.commercialDate - b.commercialDate
        }, {
            id: "technicalClosure",
            Header: "bouclage technique",
            Cell: (row) => { return rowFormat(row, row.original.technicalClosure) },
            accessor: val => val,
            sortMethod: (a,b) => a.technicalClosure - b.technicalClosure
        }];

        setCols(tabColumns);
    }

    const adjustCurrentParu = () => {
        if (scrollRef) {
            let offset = 0;

            for (let index = 0; index < scrollRef.current?.view.children[0].children[0].children.length; index++) {
                const element = scrollRef.current?.view.children[0].children[0].children[index];

                if (element.id === "current") {
                    offset = element.offsetTop - 165;
                    break;
                }
            }

            scrollRef.current?.scrollTop(offset);
        }
    }

    const generateTabEvents = (infos: CurrentParutionInfos): JSX.Element[] => {
        let previousMonth = "";

        let tabEvents: JSX.Element[] = [];

        if (parutions) {
            for (let index = 0; index < parutions.length; index++) {
                const element = parutions[index];
                let date = element.releaseDate;
                let currentMonth = months[date.getMonth()] + " " + date.getFullYear();

                if (currentMonth !== previousMonth) {
                    tabEvents.push(
                        <VerticalTimelineElement
                            key={currentMonth}
                            className="vertical-timeline-element--work"
                            iconStyle={{ width: '10px', height: '10px', marginLeft: '0px', background: theme?.ColorLastGraph, color: '#fff' }}
                            icon={<div></div>}
                            position='left'
                        >
                            <h3>
                                {currentMonth}
                            </h3>
                        </VerticalTimelineElement>
                    );
                    previousMonth = currentMonth;
                }
                let formatedDate = FormatDate(element.releaseDate);
                let isCurrentParu = infos.currentParuDate === element.releaseDate;
                let idKey = isCurrentParu ? "current" : "basic";
                let classNameP = isCurrentParu ? "currentParuBuble" : "basicBuble";

                tabEvents.push(
                    <VerticalTimelineElement
                        key={formatedDate}
                        id={idKey}
                        className="vertical-timeline-element--paru"
                        iconStyle={{ width: '5px', height: '5px', background: theme?.ColorLastGraph, color: '#fff' }}
                        icon={<div></div>}
                        position='left'
                    >
                        <div className={classNameP}>
                            <p>
                                {formatedDate}
                            </p>
                        </div>
                    </VerticalTimelineElement>
                );
            }
        }

        return (tabEvents);
    }

    return (
        <div className="reactTab">
            {parutionInfos && 
                <div>
                    <div style={{ float: 'left', margin: '10px', marginTop: '0px' }}>
                        <NextParution nextParuDay={nextParutionDate} nextParuDays={parutionInfos.nextParuInDays} />
                        <div style={{ width: '350px', height: '340px', overflow: 'hidden' }}>
                            <Scrollbars ref={scrollRef}>
                                <div style={{ width: '350px', overflow: 'hidden' }}>
                                    <VerticalTimeline>
                                        {tab}
                                    </VerticalTimeline>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                    <div style={{ margin: '10px', paddingTop: '10px' }}>
                        <ReactTable
                            data={parutions}
                            columns={cols}
                            previousText={'Précédent'}
                            nextText={'Suivant'}
                            rowsText={'lignes'}
                            noDataText={''}
                            ofText={'sur'}
                            page={currentParuIdx}
                            onPageChange={(pageIndex) => {
                                setCurrentParuIdx(pageIndex)
                            }}
                            defaultPageSize={15}
                            pageSizeOptions={[15,25,50,100]}
                            className="-striped -highlight"
                        />
                    </div>
                </div>
            }
        </div>
    );
}