import { GET_GROUPE_AUDIENCE, AudienceState, AudienceActionTypes } from '../types/audience.types';

const initialState: AudienceState = {
    groupeAuds: []
}

export function audienceReducer(state = initialState, action: AudienceActionTypes): AudienceState {
    switch (action.type) {
        case GET_GROUPE_AUDIENCE:
            return ({
                groupeAuds: action.payload
            })
        default:
            return (state);
    }
}