/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { Support } from "../../data/objects/Support";

import Combobox from 'react-widgets/lib/Combobox';
import "react-widgets/dist/css/react-widgets.css";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Offer } from '../../data/objects/Offer';

interface OffersTableProps {
    support: Support,
    codeTSM: string
}

function FormatDate(date: Date) {
    return date.toLocaleDateString();
}

function rowDateFormating(row: any) {
    return <div>{FormatDate(row.value)}</div>
}

function rowFormating(val) {
    if (!val.value) return '*';

    return (<div style={{ textAlign: 'end', fontFamily: 'Montserrat', marginRight: '15px' }}>{val.value.toLocaleString() + "€"}</div>);
}

export const OffersTable = (props: OffersTableProps) => {
    const [cols, setCols] = React.useState<any>([]);
    const [years, setYears] = React.useState<number[]>([]);
    const [year, setYear] = React.useState<number>(0);
    const [tableData, setTableData] = React.useState<Offer[]>([]);

    const offers = useSelector((state : RootState) => state.offers.offers);

    React.useEffect(() => {
        let tmpCols: any = [];
        
            tmpCols.push({
                Header: "Emplacement",
                accessor: "emplacement"
            });
            tmpCols.push({
                Header: "Format",
                accessor: "format"
            });

        tmpCols.push({
            Header: "Début",
            accessor: "start",
            Cell: rowDateFormating
        });
        tmpCols.push({
            Header: "Fin",
            accessor: "end",
            Cell: rowDateFormating
        });
        tmpCols.push({
            Header: 'Prix',
            accessor: "price",
            Cell: rowFormating,
            width: 110,
        });
        setCols(tmpCols);
    }, [])

    React.useEffect(() => {
        if (offers.length > 0)
            getAllYears();
    }, [offers])

    const getAllYears = () => {
        let tmpYears: number[] = [];
        let today = new Date();
        let currentYear = today.getFullYear();

        offers.forEach(offer => {
            let year = offer.start.getFullYear();

            if (year >= currentYear && !tmpYears.includes(year))
                tmpYears.push(year);
        });
        setYears(tmpYears);
        setYear(currentYear);
    }

    React.useEffect(() => {
        if (offers.length > 0) {
            let filteredOffers: Offer[] = [];

            offers.forEach(offer => {
                if (offer.start.getFullYear() === year)
                    filteredOffers.push(offer);
            });

            setTableData(filteredOffers);
        }
    }, [year])

    return (
        <div className="mycontainer">
            <div style={{ width: 150 }}>
                <Combobox defaultValue={new Date().getFullYear()} data={years}
                    onChange={year => {
                        setYear(year);
                    }}
                />
            </div>
            <ReactTable
                data={tableData}
                columns={cols}
                previousText={'Précédent'}
                nextText={'Suivant'}
                rowsText={'lignes'}
                noDataText={''}
                ofText={'sur'}
                defaultPageSize={10}
                className="-striped -highlight"
            />
            <br />
        </div>
    );
}