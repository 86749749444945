import { IResponseToItemMap } from '../IResponseToItemMap';
import { GetEtudesApiResponse } from '../../ApiResponse/etude/GetEtudesApiResponse';
import { Etude } from '../../objects/Etude';

export class GetEtudesResToEtudeMap implements IResponseToItemMap<GetEtudesApiResponse, Etude> {
    toItem(apiRes: GetEtudesApiResponse): Etude {
        return (new Etude(
            apiRes.Id,
            apiRes.Name,
            apiRes.Code
        ));
    }
}