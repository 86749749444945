/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PRMap from 'react-pr-map';
import { useSelector } from 'react-redux';
import { AudienceCible } from '../../data/objects/AudienceCible';
import { RootState } from '../../redux/reducers/root.reducer';

interface CustomMapProps {
    grandeur: string;
    data: AudienceCible[];
    onMouseOver: (mouseOverElement: AudienceCible) => void
}

class MapData extends AudienceCible {
    public id: string = "";
    public fill: string = "";
}

class MinMax {
    public min: number = 0;
    public max: number = 0;
}

export const CustomMap = (props: CustomMapProps) => {
    const theme = useSelector((state: RootState) => state.theme.colors);

    const [tab, setTab] = React.useState<MapData[]>([]);
    const [minMax, setMinMax] = React.useState<MinMax | null>(null);

    const getMinMax = (): MinMax => {
        let min = 0;
        let max = 0;

        min = props.data[0][props.grandeur];
        props.data.forEach((audCible) => {
            if (audCible[props.grandeur] < min)
                min = audCible[props.grandeur];
            if (audCible[props.grandeur] > max)
                max = audCible[props.grandeur];
        });

        return { min: min, max: max }
    }

    const mathTrunc = (val) : number => {
        return (Math.trunc || function(x) {
            if (isNaN(x))
                return NaN;
            if (x > 0)
                return Math.floor(x);
            return Math.ceil(x);
        })(val);
    }

    const generateColor = (val: number, min: number, max: number) : string => {
        let calc = ((val - min) * (70 / (max - min))) + 50;
        let transp = mathTrunc(calc).toString();
        let str = "rgba(" + theme?.ColorR + ',' + theme?.ColorG + ',' + theme?.ColorB + ',' + transp + ')';
        return str;
    }

    React.useEffect(() => {
        if (props.data.length > 0) {
            let tmpTab: MapData[] = [];
            let minMax: MinMax = getMinMax();
            let provence: MapData | null = null;

            for (let i = 0; i < props.data.length; i++) {
                let audByCible = props.data[i];
                let tmpMapData: MapData = { ...audByCible, id: audByCible.Name, fill: generateColor(audByCible[props.grandeur], minMax.min, minMax.max) };

                if (audByCible.Name === "Provence-Alpes-Côte-d'Azur-Corse")
                    provence = tmpMapData;

                tmpTab.push(tmpMapData);
            }

            if (provence) {
                let corse = { ...provence } ;
                corse.Name = "Corse";
                corse.id = "Corse";
                tmpTab.push(corse);
            }

            setTab(tmpTab);
            setMinMax(minMax);
        }
    }, [props])

    const rowFormatting = (val: number | string): number | string => {
        if (!val)
            return '*';
        
        if (typeof val === 'string')
            return (Number(val.replace(",", ".")).toFixed(2));
        
        return val.toFixed(2);
    }

    const onMouseOver = (evt, elem) => {
        props.data.forEach(aud => {
            if (elem.id === aud.Name || (elem.id === "Corse" && aud.Name === "Provence-Alpes-Côte-d'Azur-Corse" ))
                props.onMouseOver(aud);
        });

        elem.attr('stroke', 'black');
        elem.attr('stroke-width', '2');
    }

    const onMouseOut = (evt, elem) => {
        elem.attr('stroke', 'black');
        elem.attr('stroke-width', '0');
    }

    return (
        <div>
            <PRMap
                handleMouseOver={onMouseOver}
                handleMouseOut={onMouseOut}
                height='350'
                width='400'
                viewBox='0 0 500 580'
                fill='grey'
                stroke='blue'
                data={tab}
            />
                <div style={{ height: '17px', float: 'left' }}>
                    0
                </div>
                <div style={{ marginLeft: '365px', height: '17px' }} >
                    {minMax && rowFormatting(minMax.max)}
                </div>
                <div style={{ marginTop: '0px',
                    backgroundImage: 'linear-gradient(to right, ' + theme?.ColorMin + ', ' + theme?.ColorMax + ')',
                    width: '400px',
                    height: '17px'
                }}>
                </div>
        </div>
    );
}