import Img from 'react-image-resizer';
import { AdwantedImageUrls } from '../../utility/Constants';

interface MediaBrandImgProps {
    imgPath: string | undefined;
}

export const MediaBrandImg = (props: MediaBrandImgProps) => {
    return <div style={{ cursor: "pointer" }}>
        <Img style={{marginTop: '20px'}} src={props.imgPath ? AdwantedImageUrls.MediaBrandUrl + props.imgPath : ""} height={60}/>
    </div>
}