import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GET_OJD_ANNUEL, GET_OJD_MENSUEL, OJDActionTypes } from '../types/ojd.types'
import { RootState } from '../reducers/root.reducer';
import { OJDAnnuel } from '../../data/objects/OJDAnnuel';
import { ProvidersManager } from '../../data/ProvidersManager';
import { OJDAPIProvider } from '../../data/providers/OJDAPIProvider';
import { ApiProviderConstants } from '../../utility/Constants';
import { OJDMensuel } from '../../data/objects/OJDMensuel';

export const getOjdAnnuel = (supportCode: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<OJDAPIProvider>(ApiProviderConstants.OJDApiProviderName)?.getOJDAnnuelForSupport(supportCode).then(res => {
        if (res)
            dispatch(GetSupportOJDAnnuel(res));
    });
}

export const getOjdMensuel = (supportCode: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<OJDAPIProvider>(ApiProviderConstants.OJDApiProviderName)?.getOJDMensuelForSupport(supportCode).then(res => {
        if (res)
            dispatch(GetSupportOJDMensuel(res));
    });
}

function GetSupportOJDAnnuel(ojdAnnuels: OJDAnnuel[]): OJDActionTypes {
    return ({
        type: GET_OJD_ANNUEL,
        payload: ojdAnnuels
    });
}

function GetSupportOJDMensuel(ojdMensuels: OJDMensuel[]): OJDActionTypes {
    return ({
        type: GET_OJD_MENSUEL,
        payload: ojdMensuels
    });
}