/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactTable from "react-table";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import "react-table/react-table.css";

interface TableContactsProps {
    supportCode: string
}

export const TableContacts = (props: TableContactsProps) => {
    const contacts = useSelector((state: RootState) => state.contacts.contacts);

    return (
        <div>
            {contacts &&
                <ReactTable
                    data={contacts}
                    columns={[
                        {
                            Header: "Nom",
                            accessor: "Name"
                        },
                        {
                            Header: "Prénom",
                            accessor: "FirstName",
                        },
                        {
                            Header: 'Poste',
                            accessor: "Job",
                        },
                        {
                            Header: 'Adresse email',
                            accessor: "Contacts.Email",
                            width: 250
                        }, {
                            Header: 'Téléphone',
                            accessor: "Contacts.Phone",
                            width: 120
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Précédent"
                    nextText="Suivant"
                    rowsText="lignes"
                    noDataText=""
                    ofText='sur'
                />
            }
        </div>
    );
}