import { Support } from "../../data/objects/Support";
import { OffersTable } from "./OffersTable"

interface BlockOffersProps {
    support: Support,
    codeTSM: string
}

export const BlockOffers = (props: BlockOffersProps) => {
    return (
        <div className="AppContent">
            <div className="titleother">
                <div className="title">Offres</div>
            </div>
            <OffersTable support={props.support} codeTSM={props.codeTSM} />
        </div>
    );
}