import { IAPIItem } from './IAPIItem'

export class Offer implements IAPIItem {
    public ID: number = -1;
    public name: string;
    public format: string;
    public color: string;
    public emplacement: string;
    public start: Date;
    public end: Date;
    public price: number;

    public constructor(name: string, format: string, color: string, start: Date, end: Date, price: number, emplacement: string = "") {
        this.name = name;
        this.format = format;
        this.color = color;
        this.start = start;
        this.end = end;
        this.price = price;
        this.emplacement = emplacement;
    }
}