import { Contact } from '../../data/objects/Contact';

export const GET_CONTACTS = "GET_CONTACTS";

export interface ContactsState {
    contacts: Contact[] | undefined
}

interface GetContactsAction {
    type: typeof GET_CONTACTS,
    payload: Contact[] | undefined
}

export type ContactsActionType = GetContactsAction;