import { GET_OFFERS, OfferState, OfferActionTypes } from '../types/offer.types';

const initialState: OfferState = {
    offers: []
};

export function offerReducer(state = initialState, action: OfferActionTypes): OfferState {
    switch (action.type) {
        case GET_OFFERS:
            return {
                offers: action.payload
            }
        default:
            return (state);
    }
}