import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../reducers/root.reducer';
import { ProvidersManager } from '../../data/ProvidersManager';
import { ContactsAPIProvider } from '../../data/providers/ContactsAPIProvider';
import { ApiProviderConstants } from '../../utility/Constants'
import { GET_CONTACTS, ContactsActionType } from '../types/contacts.types'
import { Contact } from '../../data/objects/Contact';

export const getContactsForSupport = (supportCode: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<ContactsAPIProvider>(ApiProviderConstants.ContactApiProviderName)?.getContacts(supportCode).then(contacts => {
        dispatch(getContacts(contacts));
    })
}

function getContacts(contacts: Contact[] | undefined) : ContactsActionType {
    return ({
        type: GET_CONTACTS,
        payload: contacts
    });
}