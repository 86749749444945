import { OJDAnnuel } from '../../data/objects/OJDAnnuel';
import { OJDMensuel } from '../../data/objects/OJDMensuel';

export const GET_OJD_ANNUEL = 'GET_OJD_ANNUEL';
export const GET_OJD_MENSUEL = 'GET_OJD_MENSUEL';

export interface OjdState {
    ojdAnnuels: OJDAnnuel[];
    ojdMensuels: OJDMensuel[];
}

interface GetSupportOJDAnnuelAction {
    type: typeof GET_OJD_ANNUEL,
    payload: OJDAnnuel[];
}

interface GetSupportOJDMensuelAction {
    type: typeof GET_OJD_MENSUEL,
    payload: OJDMensuel[]
}

export type OJDActionTypes = GetSupportOJDAnnuelAction | GetSupportOJDMensuelAction;