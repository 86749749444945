import { IAPIItem } from './IAPIItem';

export class Contact implements IAPIItem {
    public ID: number = -1;
    public FirstName: string;
    public Name: string;
    public Job: string;
    public Contacts: { Email: string, Phone: string };
    public CompanyLink: { ID: number, Code: string, Name: string };

    constructor(
        ID: number , 
        FirstName: string, 
        Name: string, 
        Job: string, 
        Contacts: { Email: string, Phone: string }, 
        CompanyLink: { ID: number, Code: string, Name: string }
    ) {
        this.ID = ID
        this.FirstName = FirstName
        this.Name = Name
        this.Job = Job
        this.Contacts = Contacts
        this.CompanyLink = CompanyLink
    }
}