/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactTable from 'react-table';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { OJDAnnuel } from '../../data/objects/OJDAnnuel';
import "react-table/react-table.css";
import { OJDTableMonth } from './OJDTableMonth';

class OJDAnnuelRowData extends OJDAnnuel {
    public Diff: number | null = null;
}

interface OJDTableProps {
    codeSupport: string
}

export const OJDTable = (props: OJDTableProps) => {
    const [ojdAnnuelData, setOjdAnnuelData] = React.useState<OJDAnnuelRowData[]>([]);
    const [ojdMonthData, setOjdMonthData] = React.useState<any[]>([]);

    const ojdAnnuels = useSelector((state: RootState) => state.ojd.ojdAnnuels);
    const ojdMensuels = useSelector((state: RootState) => state.ojd.ojdMensuels);

    const updateDiff = () => {
        let tmpOjdAnnuels = ojdAnnuels;
        let previous: OJDAnnuel | null = null;
        let tmpOjdAnnuelsData: OJDAnnuelRowData[] = [];
        
        tmpOjdAnnuels.forEach(ojdAnnuel => {
            let tmpOjd: OJDAnnuelRowData = {...ojdAnnuel, Diff: null };
            if (previous) {
                let dif =  ojdAnnuel.DiffusionPayeeFrance - previous.DiffusionPayeeFrance;
                tmpOjd.Diff = dif / ojdAnnuel.DiffusionPayeeFrance * 100;
            }
            
            tmpOjdAnnuelsData.push(tmpOjd);
            previous = ojdAnnuel;
        });

        setOjdAnnuelData(tmpOjdAnnuelsData);
    }

    const updateMonths = () => {
        let ojdMonth: any = {};
        let years: number[] = [];
        let tmpOjdYearData = ojdAnnuelData;

        ojdMensuels.forEach(ojdMensuel => {
            if (!ojdMonth[ojdMensuel.Year]) {
                ojdMonth[ojdMensuel.Year] = {};
            }
            ojdMonth[ojdMensuel.Year][ojdMensuel.Month] = ojdMensuel;

            if (!years.includes(ojdMensuel.Year)) {
                let foundY = false;

                for (let iY = 0; iY < ojdAnnuels.length; iY++) {
                    const ojY = ojdAnnuels[iY];

                    if (ojY.Year === ojdMensuel.Year) {
                        foundY = true;
                        break;
                    }
                }

                if (!foundY) {
                    tmpOjdYearData.push({
                        ID: -1,
                        Year: ojdMensuel.Year,
                        DiffusionIndividuelle: -1,
                        DiffusionTotaleFrance: -1,
                        DiffusionPayeeFrance: -1,
                        Diff: null,
                        SupportID: ojdMensuel.SupportID,
                        Abonnements: -1,
                        Ventes: -1,
                        MiseEnDistribution: -1,
                        DistDFP: -1
                    });
                }

                years.push(ojdMensuel.Year);
            }
        });

        for (let idxY = 0; idxY < years.length; idxY++) {
            const ojdYear = ojdMonth[years[idxY]];

            let previousMonth: any = null;
            for (let idxM = 1; idxM <= 12; idxM++) {
                const currentMonth = ojdYear[idxM];

                if (!currentMonth) {
                    previousMonth = null;
                    continue;
                }

                // Pour le premier mois on check décembre de l'année précédente
                if (idxM === 1) {
                    let previousYear = ojdMonth[years[idxY] - 1];
                    if (previousYear) {
                        let previousDec = previousYear[12]; // décembre l'année précédente
                        if (previousDec)
                            previousMonth = previousDec
                    }
                }

                if (!previousMonth) {
                    currentMonth.Diff = "n.c";
                    previousMonth = currentMonth;
                    continue;
                }

                let prevVal = previousMonth.DiffusionPayee;
                let curVal = currentMonth.DiffusionPayee;

                currentMonth.Diff = ((curVal - prevVal) / prevVal) * 100;
                previousMonth = currentMonth;
            }
        }
        setOjdAnnuelData(tmpOjdYearData.sort((a,b) => b.Year - a.Year));
        setOjdMonthData(ojdMonth);
    }

    React.useEffect(() => {
        if (ojdAnnuels.length !== 0 && ojdAnnuelData.length === 0)
            updateDiff();
        if (ojdMensuels.length !== 0 && ojdAnnuelData.length > 0)
            updateMonths();
    }, [ojdAnnuels, ojdMensuels, ojdAnnuelData]);

    const rowFormating = (val) => {
        if (!val.value)
            return '*';

        return (
            <div style={{ textAlign: 'end', fontFamily: 'arial', fontWeight: 'bold', marginRight: '15px' }}>
                {val.value === -1 ? "n.c" : val.value.toLocaleString()}
            </div>
        );
    }

    const rowDiffFormating = (val) => {
        var valStr;
        var color;

        if (val.value && !isNaN(val.value)) {
            let valNb = val.value.toFixed(2);
            valStr = valNb.toLocaleString() + '%';
            color = valNb < 0 ? 'red' : 'green';
        }
        else {
            valStr = '*';
            color = 'black';
        }

        return (
            <div style={{ textAlign: 'end', fontFamily: 'arial', fontWeight: 'bold', color: color, marginRight: '15px' }}>
                {valStr}
            </div>);
    }

    const subcompo = (row) => {
        return (
            <div className="subComponentOjdMonth">
                <OJDTableMonth year={row.original.Year} data={ojdMonthData[row.original.Year]} />
            </div>
        );
    }

    return (
        <div>
            {ojdAnnuelData.length > 0 && 
                <ReactTable
                /*
                // @ts-ignore */
                Height={570} 
                data={ojdAnnuelData}
                showPageSizeOptions={false}
                SubComponent={subcompo}
                columns={[{
                    Header: "Année",
                    accessor: "Year",
                    width: 100,
                }, {
                     Header: "DFP",
                     accessor: "DiffusionPayeeFrance",
                     Cell: rowFormating
                }, {
                     Header: "DFP n-1",
                     accessor: "Diff",
                     Cell: rowDiffFormating
                }]}
                previousText={'Précédent'}
                nextText={'Suivant'}
                rowsText={'lignes'}
                noDataText={''}
                ofText={'sur'}
                defaultPageSize={ojdAnnuelData.length > 0 ? ojdAnnuelData.length : 10}
                className="-striped -highlight"
             />
            }
        </div>
    );
}