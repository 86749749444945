import { IAPIItem } from './IAPIItem'

export class Parution implements IAPIItem {
    public ID: number = -1;
    public numero: number;
    public releaseDate: Date;
    public startDate: Date;
    public endDate: Date;
    public commercialDate: Date = new Date();
    public technicalClosure: Date = new Date();
    public contenu: string = "";

    public constructor(releaseDate: Date, startDate: Date, endDate: Date, numero: number = -1) {
        this.numero = numero;
        this.releaseDate = releaseDate;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}