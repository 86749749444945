import React from 'react';
/*import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { setSelectedEtude } from '../../redux/actions/etude.actions';*/
import { Etude } from '../../data/objects/Etude';
import { MenuIdConstants } from '../../utility/Constants'
import { useDispatch } from 'react-redux';
import { setPrintMode } from '../../redux/actions/print.actions';

interface MenuProps {
    supportName: string | undefined,
    supportCode: string;
    printingName: string;
    etudes: Etude[] | undefined,
}

export const Menu = (props: MenuProps) => {
    const dispatch = useDispatch();
    //const history = useHistory();

    const [currentLocation, setCurrentLocation]: [string, (currentLocation: string) => void] = React.useState("");
    //const selectedEtude = useSelector((state : RootState) => state.etudes.selectedEtude);

    const scrollToAnchor = () => {
        setCurrentLocation(window.location.hash);
    }

    const handlePrintClick = () => {
        dispatch(setPrintMode(true));
        setTimeout(Print, 100);
    }

    const Print = () => {
        window.print();
        dispatch(setPrintMode(false));
    }

    /*const handleSelectChange = (event) => {
        if (props.etudes) {
            props.etudes.forEach(etude => {
                if (etude.Code === event.target.value) {
                    dispatch(setSelectedEtude(etude));
                    history.push("/" + props.printingName.toLocaleLowerCase() + "/fiche/" + etude.Code + "/" + props.supportCode);
                    // RAJOUTER DES ACTIONS POUR SUPPRIMER LES STATES RELATIVES A LETUDE
                    return;
                }
            })
        }
    }*/

    React.useEffect(() => {
        window.onhashchange = scrollToAnchor;
    }, [])

    return (
        <div>
            <div className="menuVertical">
                <div className="title">
                    <div className="titleMenuName">{props.supportName}</div>
                    <a href={'#' + MenuIdConstants.BlockInfoGenerales} className={currentLocation === '#' + MenuIdConstants.BlockInfoGenerales ? 'currentblock' : 'baselink'}> <p>Général</p> </a>
                    <a href={'#' + MenuIdConstants.BlockAudience} className={currentLocation === '#' + MenuIdConstants.BlockAudience ? 'currentblock' : 'baselink'}> <p>Audience</p> </a>
                    <a href={'#' + MenuIdConstants.BlockMap} className={currentLocation === '#' + MenuIdConstants.BlockMap ? 'currentblock' : 'baselink'}> <p>Carte</p> </a>
                    <a href={'#' + MenuIdConstants.BlockContacts} className={currentLocation === '#' + MenuIdConstants.BlockContacts ? 'currentblock' : 'baselink'}> <p>Contacts</p> </a>
                    <a href={'#' + MenuIdConstants.BlockParutions} className={currentLocation === '#' + MenuIdConstants.BlockParutions ? 'currentblock' : 'baselink'}> <p>Parutions</p> </a>
                    <a href={'#' + MenuIdConstants.BlockOffers} className={currentLocation === '#' + MenuIdConstants.BlockOffers ? 'currentblock' : 'baselink'}> <p>Offres</p> </a>
                    <p style={{ fontSize: '20px', float: 'right', marginRight: '10px', width: '20px', color: 'white' }}  className="baselink"  onClick={handlePrintClick}>
                        <i className="fa fa-print"/>
                    </p>
                    {/*<select value={selectedEtude ? selectedEtude.Code : ""} className="etudeDropdown" style={{ float:'right', marginTop: '2px', marginRight: '5px' }} onChange={handleSelectChange} >
                        <option value={"none"}>Etude à charger</option>
                        {props.etudes && props.etudes.map((etude, idx) => {
                            return <option key={idx} value={etude.Code}>{etude.Name}</option>
                        })}
                    </select>*/}
                </div>
            </div>
        </div>
    );
}