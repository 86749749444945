import { ThemeColors } from '../../utility/Constants';

export const SET_THEME = 'SET_THEME';

export interface ThemeState {
    colors: ThemeColors | undefined
};

interface SetThemeAction {
    type: typeof SET_THEME,
    payload: ThemeColors | undefined
};

export type ThemeActionTypes = SetThemeAction;