import { APIProvider } from './APIProvider';
import { NodeBody } from '../Body/NodeBody';
import { ApiConstants } from '../../utility/Constants';
import { Offer } from '../objects/Offer';
import { GetOffersMLResponse } from '../ApiResponse/offres/GetOffersMLResponse';
import { GetOffersNodeResponse } from '../ApiResponse/offres/GetOffersNodeResponse';
import { OffersNodeMap } from '../ResponseToItemMap/offres/OffersNodeMap';
import { OffersMLMap } from '../ResponseToItemMap/offres/OffersMLMap';

export class OfferAPIProvider extends APIProvider {
    private mlMap: OffersMLMap;
    private nodeMap: OffersNodeMap;

    public constructor() {
        super();

        this.mlMap = new OffersMLMap();
        this.nodeMap = new OffersNodeMap();
    }

    public getOffersBySupportCodeTsm(supportCode: string): Promise<Offer[] | undefined> {
        return (this.getMultiple<GetOffersMLResponse, Offer>(ApiConstants.APIOffers + supportCode, this.mlMap));
    }

    public getOffersAdwBySupportId(supportId: string): Promise<Offer[] | undefined> {
        const body: NodeBody = new NodeBody(ApiConstants.APINodeSupportOffersEndpoint + supportId);
        return (this.postMultiple<GetOffersNodeResponse, Offer>(ApiConstants.APINodeEndpoint, body, this.nodeMap, false));
    }
}