import React from 'react';
import { AdwantedImageUrls } from '../../utility/Constants';
import Img from 'react-image-resizer';

interface SocieteImgProps {
    url: string | undefined;
}

export const SocieteImg = (props: SocieteImgProps) => {
    return (
        <div style={{cursor:"pointer"}}>
            <Img style={{ position: "relative"  }} src={props.url ? AdwantedImageUrls.MediaBrandUrl + props.url : ""} height={60} />
        </div>
    );
}