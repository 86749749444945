import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GET_SUPPORT, GET_SOCIETE, SupportActionTypes } from '../types/support.types'
import { RootState } from '../reducers/root.reducer';
import { Support } from '../../data/objects/Support';
import { Societe } from '../../data/objects/Societe';
import { ProvidersManager } from '../../data/ProvidersManager';
import { SupportAPIProvider } from '../../data/providers/SupportAPIProvider';
import { ApiProviderConstants } from '../../utility/Constants'

export const getSupport = (supportCodeTsm: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<SupportAPIProvider>(ApiProviderConstants.SupportApiProviderName)?.getSupport(supportCodeTsm).then(sup => {
        console.log("sup");
        console.log(sup);
        dispatch(getSupportFromApi(sup));
    });
};

export const getSupportCompany = (supportId: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<SupportAPIProvider>(ApiProviderConstants.SupportApiProviderName)?.getSupportCompany(supportId).then(soc => {
        dispatch(getSocieteFromApi(soc));
    });
};

function getSupportFromApi(support: Support | undefined) : SupportActionTypes {
    return {
        type: GET_SUPPORT,
        payload: support
    }
}

function getSocieteFromApi(societe: Societe | undefined) : SupportActionTypes {
    return {
        type: GET_SOCIETE,
        payload: societe
    }
}