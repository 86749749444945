/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { VerticalBarSeries, VerticalBarSeriesPoint, XYPlot, XAxis, YAxis, Hint } from 'react-vis';
import './GraphOJD.css'

interface GraphOJDProps {
    codeSupport: string;
}

export const GraphOJD = (props: GraphOJDProps) => {
    const [dataTab, setDataTab] = React.useState<VerticalBarSeriesPoint[]>([]);
    const [hoveredPoint, setHoveredPoint] = React.useState<VerticalBarSeriesPoint | null>(null);

    const ojdAnnuels = useSelector((state: RootState) => state.ojd.ojdAnnuels);
    const theme = useSelector((state: RootState) => state.theme.colors);

    React.useEffect(() => {
        if (ojdAnnuels.length > 0) {
            let tmpDataTab: VerticalBarSeriesPoint[] = [];
            ojdAnnuels.forEach(ojdAnnuel => {
                tmpDataTab.push({ x: ojdAnnuel.Year, y: ojdAnnuel.DiffusionPayeeFrance });
            });
            tmpDataTab[tmpDataTab.length - 1].color = theme?.ColorLastGraph;
            setDataTab(tmpDataTab.splice(tmpDataTab.length - 5));
        }
    }, [ojdAnnuels]);

    return (
        <div style={{ marginLeft: '5px' }}>
            <p className="graphOJDTitle">Diffusion France Payée</p>
            <div className="containerGraph">
                <XYPlot className="verticalbarseriesexample" xType="ordinal" width={250} height={250}>
                    <XAxis tickSize={1} style={{ line: { strokeWidth: '0.5px' } }} tickSizeOuter={1} />
                    <YAxis tickSizeOuter={2} style={{ line: { strokeWidth: '0.5px' } }} tickTotal={10} tickLabelAngle={35} tickFormat={v => {
                        return (v / 1000 + 'k');
                    }}/>
                    <VerticalBarSeries
                        colorType="literal"
                        color={theme?.ColorGraph}
                        data={dataTab}
                        barWidth={0.85}
                        onValueMouseOver={(datapoint) => {
                            setHoveredPoint(datapoint);
                        }}
                        onValueMouseOut={() => {
                            setHoveredPoint(null);
                        }}
                    />
                    {hoveredPoint && 
                        <Hint value={hoveredPoint}>
                            <div className="tipsgraphojd">
                                {new Intl.NumberFormat().format(hoveredPoint.y)}
                            </div>
                        </Hint>
                    }
                </XYPlot>
            </div>
        </div>
    );
}