import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GET_ETUDES, SET_SELECTED_ETUDE, EtudeActionTypes } from '../types/etude.types';
import { RootState } from '../reducers/root.reducer';
import { Etude } from '../../data/objects/Etude';
import { ProvidersManager } from '../../data/ProvidersManager';
import { EtudeAPIProvider } from '../../data/providers/EtudeAPIProvider';
import { ApiProviderConstants } from '../../utility/Constants'

export const getEtudes = () : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    ProvidersManager.Singleton().getProvider<EtudeAPIProvider>(ApiProviderConstants.EtudeApiProviderName)?.getEtudes().then(etudes => {
        dispatch(getEtudesFromApi(etudes));
    });
};

export const setSelectedEtude = (etude: Etude) : EtudeActionTypes => {
    return ({
        type: SET_SELECTED_ETUDE,
        payload: etude
    })
};

function getEtudesFromApi(etudes: Etude[] | undefined) : EtudeActionTypes {
    return ({
        type: GET_ETUDES,
        payload: etudes
    });
}