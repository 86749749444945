export interface PrintState {
    printMode: boolean;
}

export const SET_PRINT_MODE = 'SET_PRINT_MODE';

interface SetPrintModeAction {
    type: typeof SET_PRINT_MODE,
    payload: boolean
}

export type PrintActionTypes = SetPrintModeAction;