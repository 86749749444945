import { ApiProviderConstants, ApiProviderConstantsType } from '../utility/Constants';
import { APIProvider } from './providers/APIProvider';
import { ParutionAPIProvider } from './providers/ParutionAPIProvider';
import { SupportAPIProvider } from './providers/SupportAPIProvider';
import { EtudeAPIProvider } from './providers/EtudeAPIProvider';
import { AudienceAPIProvider } from './providers/AudienceAPIProvider';
import { OJDAPIProvider } from './providers/OJDAPIProvider';
import { ContactsAPIProvider } from './providers/ContactsAPIProvider';
import { OfferAPIProvider } from './providers/OfferApiProvider';
import { LogAPIProvider } from './providers/LogAPIProvider';

export class ProvidersManager {
    private static singleton: ProvidersManager;
    private providers: Map<ApiProviderConstantsType, APIProvider> = new Map();

    private constructor() {
        this.initProviders();
    }

    private initProviders(): void {
        this.providers.set(ApiProviderConstants.SupportApiProviderName, new SupportAPIProvider());
        this.providers.set(ApiProviderConstants.ParutionApiProviderName, new ParutionAPIProvider());
        this.providers.set(ApiProviderConstants.EtudeApiProviderName, new EtudeAPIProvider());
        this.providers.set(ApiProviderConstants.AudienceApiProviderName, new AudienceAPIProvider());
        this.providers.set(ApiProviderConstants.OJDApiProviderName, new OJDAPIProvider());
        this.providers.set(ApiProviderConstants.ContactApiProviderName, new ContactsAPIProvider());
        this.providers.set(ApiProviderConstants.OfferApiProviderName, new OfferAPIProvider());
        this.providers.set(ApiProviderConstants.LogApiProviderName, new LogAPIProvider());
    }

    public getProvider<T extends APIProvider>(providerName: ApiProviderConstantsType): T | undefined {
        return (this.providers.get(providerName) as T);
    }

    public static Singleton(): ProvidersManager {
        if (!ProvidersManager.singleton)
            ProvidersManager.singleton = new ProvidersManager();

        return (ProvidersManager.singleton)
    }
}