import { APIProvider } from "./APIProvider";
import { AudienceGroupe } from "../objects/AudienceGroupe";
import { GetGroupeAudienceResponse } from '../ApiResponse/audience/GetGroupeAudienceResponse';
import { ApiConstants } from '../../utility/Constants';
import { GetGroupeAudienceToGroupeAudienceMap } from "../ResponseToItemMap/audience/GetGroupeAudienceToGroupeAudienceMap";

export class AudienceAPIProvider extends APIProvider {
    private getGroupeAudienceMap: GetGroupeAudienceToGroupeAudienceMap;

    public constructor() {
        super();
        this.getGroupeAudienceMap = new GetGroupeAudienceToGroupeAudienceMap();
    }

    public getGroupeAudienceByWave(supportId: number, groupId: number, waveId: number): Promise<AudienceGroupe | undefined> {
        return (this.get<GetGroupeAudienceResponse, AudienceGroupe>(ApiConstants.APISupport + supportId + ApiConstants.APIGroup + groupId + ApiConstants.APIEtude + waveId, this.getGroupeAudienceMap));
    }
}