/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { getSupport, getSupportCompany } from '../../redux/actions/support.actions';
import { getParutions } from '../../redux/actions/parution.actions';
import { getEtudes, setSelectedEtude } from '../../redux/actions/etude.actions';
import { getOffers } from '../../redux/actions/offer.actions';
import { getGroupeAudience } from '../../redux/actions/audience.actions';
import { getContactsForSupport } from '../../redux/actions/contacts.actions';
import { getOjdAnnuel, getOjdMensuel } from '../../redux/actions/ojd.actions';
import { setTheme } from '../../redux/actions/theme.actions';
import { MenuIdConstants, ProductConstantsType, ProductConstants, ColorsConstant, GroupIdsConstants, GroupIds, ApiProviderConstants } from '../../utility/Constants';
import { Menu } from '../Menu/Menu';
import { BlockDescription } from '../BlockDescription/BlockDescription';
import { BlockAudience } from '../BlockAudience/BlockAudience';
import { BlockMap } from '../BlockMap/BlockMap';
import { BlockContacts } from '../BlockContacts/BlockContacts';
import { BlockParutions } from '../BlockParutions/BlockParutions';
import { BlockOffers } from '../BlockOffers/BlockOffers';
import { ProvidersManager } from '../../data/ProvidersManager';
import { LogAPIProvider } from '../../data/providers/LogAPIProvider';

interface FicheSupportProps {
    supportCodeTsm: string;
    etudeCode: string;
    printing: ProductConstantsType;
}

const ErrorMsg = () => {
    return (
        <div style={{height: '900px', textAlign: 'center', marginTop: '10%'}}>
                Les fiches supports ne sont pas disponibles pour cette étude.<br />
                Liste des études disponibles selon souscription :<br /><br />
                *ONE NEXT<br />
                OneNext 2024 S1<br />
                OneNext 2023 S2<br />
                OneNext 2023 S1<br />
                OneNext 2022 S2<br />
                OneNext 2022 V1<br />
                OneNext 2021 V4<br />
                OneNext 2021 V3<br />
                OneNext 2021 V2<br />
                OneNext 2021 V1<br />
                OneNext 2020 V4<br />
                OneNext 2020 V3 (ex V2)<br />
                OneNext 2020 V2 (ex V1)<br /><br />
                *ONE NEXT GLOBAL<br />
                OneNext Global 2021 V3<br />
                OneNext Global 2021 V2<br />
                OneNext Global 2021 V1<br />
                OneNext Global 2020 V4<br />
                OneNext Global 2020 V3 (ex V2)<br />
                OneNext Global 2020 V2 (ex V1)<br /><br />
                *ONE NEXT INFLUENCE<br />
                OneNext Influence 2023<br />
                OneNext Influence 2022<br />
                OneNext Influence 2021<br />
                OneNext Influence 2020 V1<br />
                *ONE NEXT INFLUENCE GLOBAL<br />
                OneNext Influence Global 2020 V1<br /><br />
                *ONE<br />
                ONE 2017<br />
        </div>
    )
}

export const FicheSupport = (props: FicheSupportProps) => {
    const dispatch = useDispatch();
    const support = useSelector((state: RootState) => state.support.support);
    const parutions = useSelector((state: RootState) => state.parutions.parutions);
    const societe = useSelector((state: RootState) => state.support.societe);
    const etudes = useSelector((state: RootState) => state.etudes.etudes);
    const selectedEtude = useSelector((state: RootState) => state.etudes.selectedEtude);
    const [groupIds, setGroupIds] = useState<GroupIds | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        if (etudes) {
            console.log(etudes);
            etudes.forEach((etude) => {
                if (etude.Code === props.etudeCode) {
                    dispatch(setSelectedEtude(etude));
                    return;
                }
            });
        }
    }, [etudes])

    React.useEffect(() => {
        if (support && selectedEtude) {
            ProvidersManager.Singleton().getProvider<LogAPIProvider>(ApiProviderConstants.LogApiProviderName)?.sendLog(selectedEtude.Code, selectedEtude.Name, props.supportCodeTsm, support.Name);
            getAudiences();
            dispatch(getOffers(support, props.supportCodeTsm));
            dispatch(getParutions(ProductConstants.Medialand, props.supportCodeTsm));
            dispatch(getSupportCompany(support.IdAdw));
        }
        
        if (support && !selectedEtude) {
            setLoading(false);
        }
    }, [support]);

    React.useEffect(() => {
        getAudiences();
    }, [selectedEtude])
    
    React.useEffect(() => {
        ColorsConstant.init();
        GroupIdsConstants.init();
        dispatch(setTheme(props.printing));
        dispatch(getEtudes());
        dispatch(getSupport(props.supportCodeTsm));
        dispatch(getContactsForSupport("/" + props.supportCodeTsm));
        dispatch(getOjdAnnuel(props.supportCodeTsm));
        dispatch(getOjdMensuel(props.supportCodeTsm));
    }, []);

    const getAudiences = () => {
        if (support && selectedEtude) {
            console.log(selectedEtude);
            setLoading(false);
            setGroupIds(GroupIdsConstants.GroupIdsByEtude.get(selectedEtude.Code));

            /*dispatch(getGroupeAudience(support.ID,
                [
                    QuestionIdsConstants.SexeQuestionId,
                    QuestionIdsConstants.AgeQuestionId,
                    QuestionIdsConstants.PCSQuestionId,
                    QuestionIdsConstants.SizeFoyerQuestionId,
                    QuestionIdsConstants.ActifQuestionId,
                    QuestionIdsConstants.HabitatQuestionId,
                    QuestionIdsConstants.RegionQuestionId
                ],
                selectedEtude.ID));*/
        }
    }

    React.useEffect(() => {
        if (support && selectedEtude && groupIds) {
            let idsToSearch: number[] = [];
            for (var i = 0; i !== groupIds.ids.length; i++) {
                idsToSearch.push(groupIds.ids[i].id);
            }

            dispatch(getGroupeAudience(support.ID, idsToSearch, selectedEtude.ID));
        }
    }, [groupIds]);

    return (
        <div className="backApp">
            <link rel="stylesheet" type="text/css" href="/Themes/printing.css" media="print" />
            {loading ? <div style={{textAlign: 'center', height: '900px', lineHeight: '900px'}}>Chargement...</div> :
            groupIds ?
            <div>
            <Menu printingName={props.printing} supportCode={props.supportCodeTsm} supportName={support?.Name} etudes={etudes} />
            <div className="mainAppContent">
                <div id={MenuIdConstants.BlockInfoGenerales}>
                    <BlockDescription supportCodeTsm={props.supportCodeTsm} waveId={selectedEtude?.ID} societe={societe} support={support} parutions={parutions} groupIds={groupIds}/>
                </div>
                <div id={MenuIdConstants.BlockAudience}>
                    <BlockAudience support={support} waveId={selectedEtude?.ID} codeSupport={props.supportCodeTsm} groupIds={groupIds} />
                </div>
                <div id={MenuIdConstants.BlockMap}>
                    <BlockMap support={support} waveId={selectedEtude?.ID} groupIds={groupIds} />
                </div>
                <div id={MenuIdConstants.BlockContacts}>
                    <BlockContacts codeSupport={props.supportCodeTsm} societe={societe} />
                </div>
                {support &&
                    <div>
                        <div id={MenuIdConstants.BlockParutions}>
                            <BlockParutions support={support} />
                        </div>
                        <div id={MenuIdConstants.BlockOffers}>
                            <BlockOffers support={support} codeTSM={props.supportCodeTsm} />
                        </div>
                    </div>
                }
            </div>
            </div>
            : <div><ErrorMsg /></div>}
        </div>
    );
}