import { ParutionState, GET_PARUTIONS, ParutionsActionTypes } from '../types/parution.types';

const initialState: ParutionState = {
    parutions: []
};

export function parutionReducer(state = initialState, action: ParutionsActionTypes): ParutionState {
    switch (action.type) {
        case GET_PARUTIONS:
            return ({
                parutions: action.payload
            });
        default:
            return (state);
    }
}