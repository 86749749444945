import { IAPIItem } from './IAPIItem'

export class Support implements IAPIItem {
    public ID: number;
    public Name: string;
    public IdAdw: string;
    public Description: string;
    public Locale: string;
    public Periodicity: string;
    public Website: string;
    public Brand: string;
    public Society: string;
    public pmp: number;
    public CouvPath: string;
    public MediaBrandPath: string;

    constructor(ID: number, Name: string, IdAdw: string, Description: string, Locale: string, Periodicity: string, Website: string, Brand: string, Society: string, pmp: number, CouvPath: string, MediaBrandPath: string) {
        this.ID = ID;
        this.Name = Name;
        this.IdAdw = IdAdw;
        this.Description = Description;
        this.Locale = Locale;
        this.Periodicity = Periodicity;
        this.Website = Website;
        this.Brand = Brand;
        this.Society = Society;
        this.pmp = pmp;
        this.CouvPath = CouvPath;
        this.MediaBrandPath = MediaBrandPath;
    }
}