import Img from 'react-image-resizer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { GroupIds, PublicPicturesUrls, QuestionIdsConstants } from '../../utility/Constants';
import { HFGraph } from '../Graphs/HFGraph';
import { OJDTable } from '../Tables/OJDTable';
import { Support } from '../../data/objects/Support';
import { VerticalGraph } from '../Graphs/VerticalGraph';
import './BlockAudience.css';

interface BlockAudienceProps {
    codeSupport: string;
    waveId: number | undefined;
    support: Support | undefined;
    groupIds: GroupIds;
}

const questionLabelStyle = {
    textAnchor: 'middle',
    style: { transform: 'translate(0,7px)', fontSize: '16px', fill: 'black' }
};

export const BlockAudience = (props: BlockAudienceProps) => {
    const selectedEtude = useSelector((state: RootState) => state.etudes.selectedEtude);
    return (
        <div className="AppContent">
            {props.waveId && props.support &&
                <div>
                    <div className="title">
                        AUDIENCE / OJD
                    </div>
                    <div className="blockAudienceTableOJDContainer">
                        <div className="blockAudienceTableOJDContent">
                            <div style={{ overflow: 'hidden' }} className="row">
                                <div style={{ float: 'left', width: '50px' }}>
                                    <Img src={PublicPicturesUrls.ImageOjd} height={35} />
                                </div>
                                <p style={{ textAlign: 'center', marginLeft: "50px", marginTop: '20px' }}>Diffusion France payée</p>
                            </div>
                            <div className="blockAudienceTableOJD">
                                <OJDTable codeSupport={props.codeSupport} />
                            </div>
                        </div>
                    </div>
                    <div className="blockAudienceAllGraphs">
                        <div style={{ position: 'absolute', marginTop: '5px', marginLeft: '10px', float: 'left' }}>
                            <Img src={PublicPicturesUrls.ImageOjdOne} height={35} />
                        </div>
                        <div style={{textAlign: 'center', marginTop: '10px', fontSize: '20px'}}>{selectedEtude?.Name}</div>
                        <div style={{ width: '280px', float: 'right', marginTop: '40px' }}>
                            <HFGraph
                                questionLabel={props.groupIds.ids[1].name}
                                height={150}
                                width={150}
                                Labels={[]}
                                questionLabelStyle={questionLabelStyle}
                                legendWidth={120}
                                labelColor="black"
                                supportId={props.support.ID}
                                waveId={props.waveId}
                                questionId={props.groupIds.ids[1].id}
                            />
                            <HFGraph
                                questionLabel={props.groupIds.ids[2].name}
                                height={150}
                                width={150}
                                Labels={[]}
                                questionLabelStyle={questionLabelStyle}
                                legendWidth={120}
                                labelColor="black"
                                supportId={props.support.ID}
                                waveId={props.waveId}
                                questionId={props.groupIds.ids[2].id}
                            />
                            <HFGraph
                                questionLabel={props.groupIds.ids[5].name}
                                height={150}
                                width={150}
                                Labels={[]}
                                questionLabelStyle={questionLabelStyle}
                                legendWidth={120}
                                labelColor="black"
                                supportId={props.support.ID}
                                waveId={props.waveId}
                                questionId={props.groupIds.ids[5].id}
                            />
                        </div>
                        <div className="verticalGraphColumn" style={{ marginTop: '40px' }}>
                            <VerticalGraph
                                labelColor="black"
                                height={620}
                                width={470}
                                supportId={props.support.ID}
                                waveId={props.waveId}
                                questionIds={[props.groupIds.ids[1].id, props.groupIds.ids[2].id, props.groupIds.ids[4].id, props.groupIds.ids[6].id]}
                                titleHidden={false}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
