import { GetContactsResponse } from "../../ApiResponse/contacts/GetContactsResponse";
import { Contact } from "../../objects/Contact";
import { IResponseToItemMap } from '../IResponseToItemMap';

export class GetContactsToContactsMap implements IResponseToItemMap<GetContactsResponse, Contact> {
    toItem(apiRes: GetContactsResponse): Contact {
        return (new Contact(
            -1,
            apiRes.FirstName,
            apiRes.Name,
            apiRes.Job,
            apiRes.Contacts,
            apiRes.CompanyLink
        ));
    }
}