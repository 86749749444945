import React from 'react';
import { AudienceCible } from '../../data/objects/AudienceCible';
import Combobox from 'react-widgets/lib/Combobox';
import "react-widgets/dist/css/react-widgets.css";

interface ConfigurationMapProps {
    grandeur: string;
    hoveredElement: AudienceCible | null;
    setGrandeur: (grandeur: string) => void;
}

export const ConfigurationMap = (props: ConfigurationMapProps) => {
    const items = [{ g: "Grp", label: "Aud" }, { g: "Composition", label: "Composition %" }, { g: "Affinite", label: "Affinité" }];
    const [currentRegion, setCurrentRegion] = React.useState<string>("");
    const [currentGrp, setCurrentGrp] = React.useState<string | number>("");
    const [currentCompo, setCurrentCompo] = React.useState<string>("");
    const [currentAffi, setCurrentAffi] = React.useState<string>("");

    const rowFormating = (val) => {
        if (!val)
            return '*';

        if (typeof val === "string")
            return Number(val.replace(",", ".")).toFixed(2);

        return val.toFixed(2);
    }

    React.useEffect(() => {
        if (props.hoveredElement) {
            setCurrentRegion(props.hoveredElement.Name);
            setCurrentGrp(rowFormating(props.hoveredElement.Grp));
            setCurrentCompo(rowFormating(props.hoveredElement.Composition) + '%');
            setCurrentAffi(rowFormating(props.hoveredElement.Affinite));
        }
    }, [props.hoveredElement])
    
    return (
        <div className="configurationContent">
            <table>
                <tbody>
                    <tr>
                        <th><p>Découpage :</p></th>
                        <td>
                            <Combobox defaultValue={items[0]} data={items} textField="label"
                                onChange={selectedGrandeur => {
                                    if (typeof selectedGrandeur !== "string")
                                        props.setGrandeur(selectedGrandeur.g)
                                }} />
                        </td>
                    </tr>
                    <tr>
                        <th><p>Région :</p></th>
                        <td><span className="dataText">{currentRegion}</span></td>
                    </tr>
                    <tr>
                        <th><p>GRP :</p></th>
                        <td><span className="dataText">{currentGrp}</span></td>
                    </tr>
                    <tr>
                        <th><p>Composition % :</p></th>
                        <td><span className="dataText">{currentCompo}</span></td>
                    </tr>
                    <tr>
                        <th><p>Affinité :</p></th>
                        <td><span className="dataText">{currentAffi}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}