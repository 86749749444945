/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { GroupIds, PublicPicturesUrls, QuestionIdsConstants } from '../../utility/Constants';
import Img from 'react-image-resizer';
import { VerticalGraph } from '../Graphs/VerticalGraph';
import { Support } from '../../data/objects/Support';
import { AudienceCible } from '../../data/objects/AudienceCible';
import { RootState } from '../../redux/reducers/root.reducer';
import { CustomMap } from './CustomMap';
import './BlockMap.css';
import { ConfigurationMap } from './ConfigurationMap';

interface BlockMapProps {
    waveId: number | undefined;
    support: Support | undefined;
    groupIds: GroupIds;
}

export const BlockMap = (props: BlockMapProps) => {
    const [targetAudsForMap, setTargetAudsForMap] = React.useState<AudienceCible[]>([]);
    const [hoveredElement, setHoveredElement] = React.useState<AudienceCible | null>(null);
    const [grandeur, setGrandeur] = React.useState<string>("Grp");

    const groupeAuds = useSelector((state: RootState) => state.audiences.groupeAuds);

    React.useEffect(() => {
        let regionId: number = -1;
        props.groupIds.ids.forEach(groupId => {
            if (groupId.name === "Region") {
                regionId = groupId.id;
            }
        });

        groupeAuds.forEach((groupeAud) => {
            if (groupeAud.ID === regionId) {
                setTargetAudsForMap(groupeAud.AudienceByCibles);
                return;
            }
        });
    }, [groupeAuds])

    return (
        <div className="mapstats">
            { props.support && props.waveId &&
                <div className="AppContent">
                    <div className="title">
                        Carte de France
                    </div>
                    <div style={{ float: 'left', width: '50px' }}>
                        <Img src={PublicPicturesUrls.ImageOjdOne} height={35} />
                    </div>
                    <div>
                        <div className="basecolumn">
                            <div className="mapcontainer" style={{ marginLeft: '-10px' }}>
                                <CustomMap grandeur={grandeur} data={targetAudsForMap} onMouseOver={over => setHoveredElement(over)} />
                            </div>
                        </div>
                        <div className="mapConf">
                            <ConfigurationMap grandeur={grandeur} hoveredElement={hoveredElement} setGrandeur={setGrandeur} />
                        </div>
                        <div className="verticalGraphMap" style={{ marginRight: '10px', marginTop: '40px' }}>
                            <VerticalGraph
                                labelColor="black"
                                height={440}
                                width={440}
                                titleHidden={true}
                                questionIds={[props.groupIds.ids[0].id]}
                                supportId={props.support.ID}
                                waveId={props.waveId}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}