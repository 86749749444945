import { Offer } from '../../data/objects/Offer';

export const GET_OFFERS = 'GET_OFFERS';

export interface OfferState {
    offers: Offer[];
};

interface GetOffersAction {
    type: typeof GET_OFFERS;
    payload: Offer[];
};

export type OfferActionTypes = GetOffersAction;