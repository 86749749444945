import { combineReducers } from 'redux';
import { supportReducer } from './support.reducer';
import { parutionReducer } from './parution.reducer';
import { themeReducer } from './theme.reducer';
import { etudeReducer } from './etude.reducer';
import { audienceReducer } from './audience.reducer';
import { ojdReducer } from './ojd.reducer';
import { contactsReducer } from './contacts.reducer';
import { offerReducer } from './offer.reducer';
import { printReducer } from './print.reducer';

export const rootReducer = combineReducers({
    support: supportReducer,
    parutions: parutionReducer,
    theme: themeReducer,
    etudes: etudeReducer,
    audiences: audienceReducer,
    ojd: ojdReducer,
    contacts: contactsReducer,
    offers: offerReducer,
    print: printReducer
});

export type RootState = ReturnType<typeof rootReducer>;