import { GetContactsResponse } from "../ApiResponse/contacts/GetContactsResponse";
import { Contact } from "../objects/Contact";
import { APIProvider } from "./APIProvider";
import { ApiConstants } from "../../utility/Constants";
import { GetContactsToContactsMap } from "../ResponseToItemMap/contacts/GetContactsToContactsMap";

export class ContactsAPIProvider extends APIProvider {
    private getMap: GetContactsToContactsMap;

    public constructor() {
        super();
        this.getMap = new GetContactsToContactsMap();
    }

    public getContacts(supportCode: string): Promise<Contact[] | undefined> {
        return (this.getMultiple<GetContactsResponse, Contact>(ApiConstants.APIContactsEndpoint + supportCode, this.getMap));
    }
}