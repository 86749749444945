import { PrintState, SET_PRINT_MODE } from "../types/print.types";

const initialState : PrintState = {
    printMode: false
};

export function printReducer(state = initialState, action: any): PrintState {
    switch (action.type) {
        case SET_PRINT_MODE:
            return ({
                printMode: action.payload
            });
        default:
            return (state);
    }
}