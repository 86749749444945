import React from 'react';
import { Circle } from 'react-shapes';
import { SocieteImg } from '../BlockDescription/SocieteImg';

import './MainContact.css';

interface MainContactProps {
    url: string;
}

export const MainContact = (props: MainContactProps) => {
    return (
        <div>
            <div className="title" style={{ background: 'transparent' }}>Contact principal</div>
            <div className="ficheContact">
                <div className="mycontainer" style={{ position: 'relative' }}>
                    <div className="row">
                        <div className="imgprofile">
                            <Circle r={50} fill={{ color: 'rgb(235, 235, 235)' }} />
                        </div>
                        <div className="contactData" style={{ float: 'right', width: '65%' }}>
                            <p className="nom">Prenom NOM</p>
                            <p className="poste">Intitulé poste</p>
                            <br />                 <br />
                            <p>Tel: 00 00 00 00 00</p>
                            <p>mail@mail.com</p>
                        </div>
                    </div>
                    <div className="strokeBottom"></div>

                    <div className="contactSocieteImgContainer">
                        <SocieteImg url={props.url} />
                    </div>
                </div>
            </div>
        </div>
    );
}