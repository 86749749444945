import { Support } from '../../data/objects/Support';
import { Societe } from '../../data/objects/Societe';

export const GET_SUPPORT = 'GET_SUPPORT';
export const GET_SOCIETE = 'GET_SOCIETE';

export interface SupportState {
    support: Support | undefined;
    societe: Societe | undefined;
}

interface GetSupportAction {
    type: typeof GET_SUPPORT,
    payload: Support | undefined
}

interface GetSocieteAction {
    type: typeof GET_SOCIETE,
    payload: Societe | undefined
}

export type SupportActionTypes = GetSupportAction | GetSocieteAction;