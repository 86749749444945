import { GetSupportCompanyApiResponse } from '../../ApiResponse/support/GetSupportCompanyApiResponse';
import { IResponseToItemMap } from '../IResponseToItemMap';
import { Societe } from '../../objects/Societe';

export class GetSupportCompanyToCompanyMap implements IResponseToItemMap<GetSupportCompanyApiResponse, Societe> {
    toItem(apiRes: GetSupportCompanyApiResponse): Societe {
        return (new Societe(
            apiRes.logo
        ));
    }
}