import { GetSupportsApiResponse } from '../../ApiResponse/support/GetSupportsApiResponse';
import { IResponseToItemMap } from '../IResponseToItemMap';
import { Support } from '../../objects/Support';

export class GetSupportsResToSupportMap implements IResponseToItemMap<GetSupportsApiResponse, Support> {
    toItem(apiRes: GetSupportsApiResponse): Support {
        return (new Support(
            apiRes.ID,
            apiRes.Name,
            apiRes.IdAdw,
            apiRes.Description,
            apiRes.Locale,
            apiRes.Periodicity,
            apiRes.Website,
            apiRes.Brand,
            apiRes.Society,
            apiRes.pmp,
            apiRes.CouvPath,
            apiRes.MediaBrandPath
        ));
    }
}