import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GET_GROUPE_AUDIENCE, AudienceActionTypes } from '../types/audience.types';
import { RootState } from '../reducers/root.reducer';
import { AudienceGroupe } from '../../data/objects/AudienceGroupe';
import { ProvidersManager } from '../../data/ProvidersManager';
import { AudienceAPIProvider } from '../../data/providers/AudienceAPIProvider';
import { ApiProviderConstants } from '../../utility/Constants'

export const getGroupeAudience = (supportId: number, groupIds: number[], waveId: number) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    let groupeAuds: AudienceGroupe[] = [];
    for (let index = 0; index < groupIds.length; index++) {
        let audGroupe = await ProvidersManager.Singleton().getProvider<AudienceAPIProvider>(ApiProviderConstants.AudienceApiProviderName)?.getGroupeAudienceByWave(supportId, groupIds[index], waveId);

        if (audGroupe)
            groupeAuds.push(audGroupe);
    }

    dispatch(getGroupeAudienceFromApi(groupeAuds));
}

function getGroupeAudienceFromApi(auds: AudienceGroupe[]) : AudienceActionTypes {
    return ({
        type: GET_GROUPE_AUDIENCE,
        payload: auds
    });
}