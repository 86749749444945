import { ApiConstants } from "../../utility/Constants";
import { LogAPIBody } from "../Body/LogAPIBody";
import { APIProvider } from "./APIProvider";

export class LogAPIProvider extends APIProvider {
    public constructor() {
        super();
    }

    public sendLog(codeEtude: string, etudeName: string, codeSupport: string, nameSupport: string) {
        let body = new LogAPIBody();

        body.Report.Support.CodeTSM = codeSupport;
        body.Report.Support.Name = nameSupport;
        body.Report.Etude.Code = codeEtude;
        body.Report.Etude.Name = etudeName;

        this.post(ApiConstants.LogAPIEndpoint, body, undefined, false, true);
    }
}