import { AudienceGroupe } from '../../data/objects/AudienceGroupe';

export const GET_GROUPE_AUDIENCE = 'GET_GROUPE_AUDIENCE';

export interface AudienceState {
    groupeAuds: AudienceGroupe[]
}

interface GetGroupeAudienceAction {
    type: typeof GET_GROUPE_AUDIENCE,
    payload: AudienceGroupe[]
}

export type AudienceActionTypes = GetGroupeAudienceAction;