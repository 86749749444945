import { Parution } from "../../data/objects/Parution";

export const GET_PARUTIONS = 'GET_PARUTIONS';

export interface ParutionState {
    parutions: Parution[] | undefined
};

interface GetParutionsAction {
    type: typeof GET_PARUTIONS,
    payload: Parution[] | undefined
}

export type ParutionsActionTypes = GetParutionsAction;