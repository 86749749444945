interface DescriptionSupportProps {
    description: string | undefined;
}

export const DescriptionSupport = (props: DescriptionSupportProps) => {
    return (
        <div className="descriptionSupportContainer">
            <div dangerouslySetInnerHTML={{__html: props.description ? props.description : ""}} />
        </div>
    );
};