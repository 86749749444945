import { GET_CONTACTS, ContactsState, ContactsActionType } from '../types/contacts.types';

const initialState: ContactsState = {
    contacts: undefined
}

export function contactsReducer(state = initialState, action: ContactsActionType): ContactsState {
    switch (action.type) {
        case GET_CONTACTS:
            return ({
                contacts: action.payload
            });
        default:
            return (state);
    }
}