import { Etude } from '../../data/objects/Etude';

export const GET_ETUDES = 'GET_ETUDES';
export const SET_SELECTED_ETUDE = 'SET_SELECTED_ETUDE';

export interface EtudeState {
    etudes: Etude[] | undefined,
    selectedEtude: Etude | undefined
}

interface GetEtudesAction {
    type: typeof GET_ETUDES,
    payload: Etude[] | undefined
}

interface SetSelectedEtudeAction {
    type: typeof SET_SELECTED_ETUDE,
    payload: Etude | undefined
}

export type EtudeActionTypes = GetEtudesAction | SetSelectedEtudeAction;