import { GET_ETUDES, EtudeState, EtudeActionTypes, SET_SELECTED_ETUDE } from '../types/etude.types';

const initialState: EtudeState = {
    etudes: [],
    selectedEtude: undefined
};

export function etudeReducer(state = initialState, action: EtudeActionTypes): EtudeState {
    switch (action.type) {
        case GET_ETUDES:
            return ({
                etudes: action.payload,
                selectedEtude: state.selectedEtude
            })
        case SET_SELECTED_ETUDE:
            return ({
                etudes: state.etudes,
                selectedEtude: action.payload
            });
        default:
            return (state);
    }
}