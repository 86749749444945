import React from 'react';
import '../node_modules/react-vis/dist/style.css';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { configureStore } from './redux/store';
import { FicheSupportBase } from './components/FicheSupportBase';
import { ProductConstants } from './utility/Constants'

const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/medialand/fiche/:etude/:tsm" component={(match: any) => <FicheSupportBase printing={ProductConstants.Medialand} supportCodeTsm={match.match.params.tsm} etudeCode={match.match.params.etude} /> } />
                    <Route path="/adwanted/fiche/:etude/:tsm" component={(match: any) => <FicheSupportBase printing={ProductConstants.Adwanted} supportCodeTsm={match.match.params.tsm} etudeCode={match.match.params.etude} /> } />
                    <Redirect from="/medialand/fiche/:tsm" to="/medialand/fiche/OneNext2022V1/:tsm"/>
                    <Redirect from="/adwanted/fiche/:tsm" to="/adwanted/fiche/OneNext2022V1/:tsm"/>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;