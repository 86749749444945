import { GetOffersNodeResponse } from '../../ApiResponse/offres/GetOffersNodeResponse';
import { Offer } from '../../objects/Offer';
import { IResponseToItemMap } from '../IResponseToItemMap';

export class OffersNodeMap implements IResponseToItemMap<GetOffersNodeResponse, Offer> {
    public toItem(apiRes: GetOffersNodeResponse): Offer {
        let ret = new Offer(
            apiRes.name,
            apiRes.labelFormat,
            apiRes.labelColor,
            new Date(apiRes.begin),
            new Date(apiRes.end),
            apiRes.price
        );

        return (ret);
    }
}