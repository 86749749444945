import { MainContact } from "./MainContact"
import { Societe } from "../../data/objects/Societe";
import { TableContacts } from "./TableContacts";

interface BlockContactsProps {
    codeSupport: string;
    societe: Societe | undefined
}

export const BlockContacts = (props: BlockContactsProps) => {
    return (
        <div className="listingContacts">
            { props.societe && 
                <div className="AppContent">
                    <div className="titleother">
                        <div className="title">LISTING DES CONTACTS</div>
                    </div>

                    <div className="blockContact">
                        <div style={{ float: 'left' }}>
                            <MainContact url={props.societe?.Logo} />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <TableContacts supportCode={props.codeSupport} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}