import React from 'react';
import { Support } from '../../data/objects/Support';
import { ParutionsTable } from './ParutionsTable';

interface BlockParutionsProps {
    support: Support
}

export const BlockParutions = (props: BlockParutionsProps) => {
    return (
        <div className="AppContent">
            <div className="titleother">
                <div className="title">Parutions</div>
            </div>
            <ParutionsTable support={props.support} />
        </div>
    );
}