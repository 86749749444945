/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactTable from 'react-table';

interface OJDTableMonthProps {
    year: number,
    data: any
}

class OJDTableMonthData {
    label: string = "";
    DiffusionPayee: number | "n.c" = 'n.c';
    Diff: number| "n.c" = "n.c";
}

export const OJDTableMonth = (props: OJDTableMonthProps) => {
    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ];

    const [data, setData] = React.useState<OJDTableMonthData[]>([]);

    React.useEffect(() => {
        let tmpData: OJDTableMonthData[] = [];

        for (let idx = 1; idx <= 12; idx ++) {
            const element = props.data[idx];

            if (!element)
                tmpData.push({ label: months[idx - 1], DiffusionPayee: "n.c", Diff: "n.c" });
            else
                tmpData.push({ label: months[idx - 1], DiffusionPayee: element.DiffusionPayee, Diff: element.Diff });
        }
        setData(tmpData);
    }, [])

    const rowFormating = (val) => {
        if (!val.value) return '*';

        return (<div style={{ textAlign: 'end', fontFamily: 'arial', fontWeight: 'bold', marginRight: '15px' }}>{val.value.toLocaleString()}</div>);
    }

    const rowDiffFormating = (val) => {
        var valStr;
        var color;

        if (typeof val.value != "string") {
            let valNb = val.value.toFixed(2);
            valStr = valNb.toLocaleString() + '%';
            color = valNb < 0 ? 'red' : 'green';
        }
        else {
            valStr = '*';
            color = 'black';
        }

        return (
            <div style={{ textAlign: 'end', fontFamily: 'arial', fontWeight: 'bold', color: color, marginRight: '15px' }}>
                {valStr}
            </div>);
    }

    return (
        <ReactTable
            data={data}
            showPageSizeOptions={false}
            showPagination={false}
            showPaginationTop={false}
            showPaginationBottom={false}
            columns={[{
                Header: "Mois",
                accessor: "label",
                width: 83,
            }, {
                Header: "DFP",
                accessor: "DiffusionPayee",
                Cell: rowFormating
            }, {
                Header: "DFP n-1",
                accessor: "Diff",
                Cell: rowDiffFormating
            },
            ]}

            defaultPageSize={12}
            className="-striped -highlight"
        />
    );
}