import { GetEtudesApiResponse } from "../ApiResponse/etude/GetEtudesApiResponse";
import { APIProvider } from "./APIProvider";
import { Etude } from '../objects/Etude';
import { GetEtudesResToEtudeMap } from "../ResponseToItemMap/etude/GetEtudesResToEtudeMap";
import { ApiConstants } from "../../utility/Constants";

export class EtudeAPIProvider extends APIProvider {
    private getMap: GetEtudesResToEtudeMap;

    public constructor() {
        super();
        this.getMap = new GetEtudesResToEtudeMap();
    }

    public getEtudes(): Promise<Etude[] | undefined> {
        return (this.getMultiple<GetEtudesApiResponse, Etude>(ApiConstants.APIEtudeEndpoint, this.getMap));
    }
}