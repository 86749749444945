import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { Circle } from 'react-shapes';
import './NextParution.css';

interface NextParutionProps {
    nextParuDays: number;
    nextParuDay: string;
}

export const NextParution = (props: NextParutionProps) => {
    const theme = useSelector((state: RootState) => state.theme.colors);    

    return (
        <div className="ficheNextParution">
            <div className="ficheContact">
                <div className="mycontainer">
                    <div className="row">
                        <div className="imgprofile">
                            <Circle r={50} fill={{ color: theme?.ColorLastGraph }} />
                            <div className="nextParuNumber">{props.nextParuDays}</div>
                        </div>
                        <div className="contactData" style={{ float: 'right', width: '65%' }} >
                            <p className="textDaysNextParu">{props.nextParuDays === 1 ? 'jour restant' : 'jours restants'} avant la prochaine parution</p>
                            <br />
                            <p className="textDateNextParu">{props.nextParuDay}</p>
                        </div>
                    </div>
                    <div className="strokeBottomParution"></div>
                </div>
            </div>
        </div>
    );
}