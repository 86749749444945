import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './reducers/root.reducer';

export const configureStore = () => {
    const middlewareEnhancer = applyMiddleware(thunkMiddleware);
    const composedEnhancers = compose(middlewareEnhancer);
    const store = createStore(rootReducer, undefined, composedEnhancers);

    return (store);
}