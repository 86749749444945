import axios from 'axios';
import { IAPIResponse } from '../ApiResponse/IAPIResponse';
import { ApiConstants } from '../../utility/Constants';
import { IAPIItem } from '../objects/IAPIItem';
import { IAPIBody } from '../Body/IAPIBody';
import { IResponseToItemMap } from '../ResponseToItemMap/IResponseToItemMap';

export class APIProvider {
    baseUrl: string;

    protected constructor() {
        this.baseUrl = ApiConstants.HostApi;
    }

    protected get<T extends IAPIResponse, R extends IAPIItem>(url: string, map: IResponseToItemMap<T, R>, includeFormatJson: boolean = true) : Promise<R | undefined> {
        return (axios.get<T>(this.baseUrl + url + (includeFormatJson ? ApiConstants.ApiFormatsJson : "")).then(res => {
            return (map.toItem(res.data));
        }).catch(err => {
            return (undefined);
        }));
    }

    protected getMultiple<T extends IAPIResponse, R extends IAPIItem>(url: string, map: IResponseToItemMap<T, R>, includeFormatJson: boolean = true) : Promise<R[] | undefined> {
        return (axios.get<T[]>(this.baseUrl + url + (includeFormatJson ? ApiConstants.ApiFormatsJson : "")).then(res => {
            let ret: R[] = [];

            res.data.forEach(single => {
                ret.push(map.toItem(single));
            });

            return (ret);
        }).catch(err => {
            return (undefined)
        }));
    }

    protected post<T extends IAPIResponse, R extends IAPIItem>(url: string, body: IAPIBody, map: IResponseToItemMap<T, R> | undefined = undefined, includeFormatJson: boolean = true, overrideUrl = false) : Promise<R | undefined> {
        let completeURL: string;

        if (overrideUrl)
            completeURL = url;
        else
            completeURL = this.baseUrl + url + (includeFormatJson ? ApiConstants.ApiFormatsJson : "");

        
        return (axios.post<T>(completeURL, body).then(res => {
            if (map)
                return (map.toItem(res.data));
        })).catch(err => {
            return (undefined);
        });
    }

    protected postMultiple<T extends IAPIResponse, R extends IAPIItem>(url: string, body: IAPIBody, map: IResponseToItemMap<T, R>, includeFormatJson: boolean = true) : Promise<R[] | undefined> {
        return (axios.post<T[]>(this.baseUrl + url + (includeFormatJson ? ApiConstants.ApiFormatsJson : ""), body).then(res => {
            let ret: R[] = [];

            res.data.forEach(single => {
                ret.push(map.toItem(single));
            });

            return (ret);
        })).catch(err => {
            return (undefined);
        });
    }
}