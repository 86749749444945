import { GetSupportOJDAnnuelApiResponse } from '../ApiResponse/diffusions/GetSupportOJDAnnuelApiResponse';
import { GetSupportOJDMensuelApiResponse } from '../ApiResponse/diffusions/GetSupportOJDMensuelApiResponse';
import { OJDAnnuel } from '../objects/OJDAnnuel';
import { OJDMensuel } from '../objects/OJDMensuel';
import { GetSupportOJDAnnuelMap } from '../ResponseToItemMap/diffusion/GetSupportOJDAnnuelMap';
import { GetSupportOJDMensuelMap } from '../ResponseToItemMap/diffusion/GetSupportOJDMensuelMap';
import { APIProvider } from './APIProvider';
import { ApiConstants } from '../../utility/Constants'

export class OJDAPIProvider extends APIProvider {
    private ojdAnnuelMap: GetSupportOJDAnnuelMap;
    private ojdMensuelMap: GetSupportOJDMensuelMap;

    public constructor() {
        super();
        this.ojdAnnuelMap = new GetSupportOJDAnnuelMap();
        this.ojdMensuelMap = new GetSupportOJDMensuelMap();
    }

    public getOJDAnnuelForSupport(codeSupport: string): Promise<OJDAnnuel[] | undefined> {
        return (this.getMultiple<GetSupportOJDAnnuelApiResponse, OJDAnnuel>(ApiConstants.APISupportOJD + codeSupport, this.ojdAnnuelMap));
    }

    public getOJDMensuelForSupport(codeSupport: string): Promise<OJDMensuel[] | undefined> {
        return (this.getMultiple<GetSupportOJDMensuelApiResponse, OJDMensuel>(ApiConstants.APISupportOJDMens + codeSupport, this.ojdMensuelMap));
    }
}