import { APIProvider } from './APIProvider';
import { NodeBody } from '../Body/NodeBody';
import { GetSupportsApiResponse } from '../ApiResponse/support/GetSupportsApiResponse';
import { GetSupportCompanyApiResponse } from '../ApiResponse/support/GetSupportCompanyApiResponse';
import { GetSupportsResToSupportMap } from '../ResponseToItemMap/support/GetSupportsResToSupportMap';
import { GetSupportCompanyToCompanyMap } from '../ResponseToItemMap/support/GetSupportCompanyToCompanyMap';
import { Support } from '../objects/Support';
import { Societe } from '../objects/Societe';
import { ApiConstants } from '../../utility/Constants';

export class SupportAPIProvider extends APIProvider {
    private getResMap: GetSupportsResToSupportMap;
    private getCompanyMap: GetSupportCompanyToCompanyMap;

    public constructor() {
        super();
        this.getResMap = new GetSupportsResToSupportMap();
        this.getCompanyMap = new GetSupportCompanyToCompanyMap();
    }

    public getSupport(supportCode: string) : Promise<Support | undefined> {
        return (this.get<GetSupportsApiResponse, Support>(ApiConstants.APISupportEndpoint + supportCode, this.getResMap));
    }

    public getSupportCompany(supportId: string) : Promise<Societe | undefined> {
        const body: NodeBody = new NodeBody(ApiConstants.APINodeSupportCompanyEndpoint + supportId);
        return (this.postMultiple<GetSupportCompanyApiResponse, Societe>(ApiConstants.APINodeEndpoint, body, this.getCompanyMap, false).then(data => {
            if (data && data.length > 0)
                return (data[0]);
            else
                return undefined;
        }).catch(err => {
            return undefined
        }));
    }
}