import { OjdState, GET_OJD_ANNUEL, GET_OJD_MENSUEL, OJDActionTypes } from '../types/ojd.types';

const initialState: OjdState = {
    ojdAnnuels: [],
    ojdMensuels: []
};

export function ojdReducer(state = initialState, action: OJDActionTypes): OjdState {
    switch (action.type) {
        case GET_OJD_ANNUEL:
            return ({
                ojdAnnuels: action.payload,
                ojdMensuels: state.ojdMensuels
            })
        case GET_OJD_MENSUEL:
            return ({
                ojdAnnuels: state.ojdAnnuels,
                ojdMensuels: action.payload
            })
        default:
            return (state);
    }
};