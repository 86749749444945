import { IAPIBody } from "./IAPIBody";

class Report {
    Category: string = "Fiche titre";
    Action: string = "navigation";
    Description: string = "Affichage d'une fiche titre";
    Support: { Name: string, CodeTSM: string } = { Name: "", CodeTSM: "" };
    Etude: { Name: string, Code: string } = { Name: "", Code: "" };
}

export class LogAPIBody implements IAPIBody {
    Layer: string = "Fiche Titre";
    User: string = "";
    Report: Report = new Report();
}