import { IAPIItem } from './IAPIItem';

export class OJDMensuel implements IAPIItem {
    public ID: number;
    public SupportID: number;
    public Year: number;
    public Month: number;
    public DiffusionPayee: number;
    public DiffusionTotale: number;
    public MiseEnDistribution: number;

    public constructor(ID: number, SupportID: number, Year: number, Month: number, DiffusionPayee: number, DiffusionTotale: number, MiseEnDistribution: number) {
        this.ID = ID;
        this.SupportID = SupportID;
        this.Year = Year;
        this.Month = Month;
        this.DiffusionPayee = DiffusionPayee;
        this.DiffusionTotale = DiffusionTotale;
        this.MiseEnDistribution = MiseEnDistribution;
    }
}