import { IResponseToItemMap } from '../IResponseToItemMap';
import { GetParutionsMLResponse } from '../../ApiResponse/parution/GetParutionsMLResponse';
import { Parution } from '../../objects/Parution';

export class ParutionsMLMap implements IResponseToItemMap<GetParutionsMLResponse, Parution> {
    public toItem(apiRes: GetParutionsMLResponse): Parution {
        let ret = new Parution(
            new Date(apiRes.parution_date),
            new Date(apiRes.debut_date),
            new Date(apiRes.fin_date),
            apiRes.numero
        );

        ret.contenu = apiRes.contenu;

        return (ret);
    }
}