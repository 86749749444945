import { IAPIItem } from './IAPIItem';

export class AudienceCible implements IAPIItem {
    public ID: number;
    public Name: string;
    public Grp: number;
    public Affinite: number;
    public Individuals: number;
    public Contacts: number;
    public Composition: number;

    public constructor(ID: number, Name: string, Grp: number, Affinite: number, Individuals: number, Contacts: number, Composition: number) {
        this.ID = ID;
        this.Name = Name;
        this.Grp = Grp;
        this.Affinite = Affinite;
        this.Individuals = Individuals;
        this.Contacts = Contacts;
        this.Composition = Composition;
    }
}