import { ThemeState, SET_THEME, ThemeActionTypes } from '../types/theme.types';

const initialState: ThemeState = {
    colors: undefined
};

export function themeReducer(state = initialState, action: ThemeActionTypes): ThemeState {
    switch (action.type) {
        case SET_THEME:
            return ({
                colors: action.payload
            });
        default:
            return (state);
    }
}