import { IAPIItem } from './IAPIItem';

export class Etude implements IAPIItem {
    public ID: number;
    public Name: string;
    public Code: string;

    constructor(ID: number, Name: string, Code: string) {
        this.ID = ID;
        this.Name = Name;
        this.Code = Code;
    }
}