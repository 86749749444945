import { GetSupportOJDAnnuelApiResponse } from '../../ApiResponse/diffusions/GetSupportOJDAnnuelApiResponse';
import { OJDAnnuel } from '../../objects/OJDAnnuel';
import { IResponseToItemMap } from '../IResponseToItemMap';

export class GetSupportOJDAnnuelMap implements IResponseToItemMap<GetSupportOJDAnnuelApiResponse, OJDAnnuel> {
    toItem(apiRes: GetSupportOJDAnnuelApiResponse): OJDAnnuel {
        return (new OJDAnnuel(
            apiRes.ID,
            apiRes.SupportID,
            apiRes.Year,
            apiRes.DiffusionPayeeFrance,
            apiRes.DiffusionTotaleFrance,
            apiRes.Abonnements,
            apiRes.Ventes,
            apiRes.DiffusionIndividuelle,
            apiRes.MiseEnDistribution,
            apiRes.DistDFP
        ));
    }
}