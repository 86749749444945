import React from 'react';
import { ProductConstantsType } from '../utility/Constants';
import { FicheSupport } from './FicheSupport/FicheSupport';

interface FicheSupportBaseProps {
    supportCodeTsm: string,
    etudeCode: string,
    printing: ProductConstantsType
}

export const FicheSupportBase = (props: FicheSupportBaseProps) => {

    return (
        <div>
            <link rel="stylesheet" href={"/Themes/" + props.printing + ".css"}/>
            <FicheSupport etudeCode={props.etudeCode} supportCodeTsm={props.supportCodeTsm} printing={props.printing} />
        </div>
    );
}