import { IResponseToItemMap } from '../IResponseToItemMap';
import { GetGroupeAudienceResponse, GetTargetAudience } from '../../ApiResponse/audience/GetGroupeAudienceResponse';
import { AudienceGroupe } from '../../objects/AudienceGroupe';
import { AudienceCible } from '../../objects/AudienceCible';

export class GetTargetAudienceToCibleAudienceMap implements IResponseToItemMap<GetTargetAudience, AudienceCible> {
    toItem(apiRes: GetTargetAudience): AudienceCible {
        return (new AudienceCible(
            apiRes.ID,
            apiRes.Name,
            apiRes.Grp,
            apiRes.Affinite,
            apiRes.Individuals,
            apiRes.Contacts,
            apiRes.Composition
        ));
    }
}

export class GetGroupeAudienceToGroupeAudienceMap implements IResponseToItemMap<GetGroupeAudienceResponse, AudienceGroupe> {
    private cibleAudienceMap: GetTargetAudienceToCibleAudienceMap;

    public constructor() {
        this.cibleAudienceMap = new GetTargetAudienceToCibleAudienceMap();
    }

    toItem(apiRes: GetGroupeAudienceResponse): AudienceGroupe {
        let audienceByCibles: AudienceCible[] = [];

        apiRes.Targets.forEach(cibleAudience => {
            audienceByCibles.push(this.cibleAudienceMap.toItem(cibleAudience));
        });

        return (new AudienceGroupe(
            apiRes.ID,
            apiRes.Name,
            audienceByCibles.reverse()
        ));
    }
}