/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { AudienceGroupe } from '../../data/objects/AudienceGroupe';
import { RootState } from '../../redux/reducers/root.reducer';
import { QuestionIdsConstantsType } from '../../utility/Constants';
import { Hint, Sunburst, LabelSeries, DiscreteColorLegend, SunburstPoint } from 'react-vis';
import './HFGraph.css';

interface HFGraphProps {
    questionLabel: string;
    height: number;
    width: number;
    questionId: QuestionIdsConstantsType;
    supportId: number
    waveId: number;
    labelColor: string;
    legendWidth: number;
    questionLabelStyle: { textAnchor: string, style: { transform: string, fontSize: string, fill: string } }
    Labels: { Index: number, Label: string }[];
}

interface Legend {
    title: string;
    color?: string;
    disabled?: boolean;
}

interface LabelStyle {
    fontSize: number;
    fill: any;
    textAnchor: "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "middle" | "end" | "start" | undefined
}

export const HFGraph = (props: HFGraphProps) => {
    const grandeur = "Composition";
    const [hoveredCell, setHoveredCell] = React.useState<SunburstPoint | false | null>(null);
    const [groupeAudsForGraph, setGroupeAudsForGraph] = React.useState<AudienceGroupe | null >(null);
    const [legends, setLegends] = React.useState<Array<Legend>>([]);
    const [dataTab, setDataTab] = React.useState<SunburstPoint | null>(null);

    const groupeAuds = useSelector((state: RootState) => state.audiences.groupeAuds);
    const theme = useSelector((state: RootState) => state.theme.colors);

    React.useEffect(() => {
        if (groupeAudsForGraph && theme) {
            let tmpLegends: Array<Legend> = [];
            let tmpDataTab: Array<SunburstPoint> = [];
            let dicoLabels = {};
            let dicoChildren = {};
            const labelStyle: LabelStyle = { fontSize: 11, fill: props.labelColor ? props.labelColor : 'grey', textAnchor: 'middle' };

            props.Labels.forEach(label => {
                dicoLabels[label.Index] = label.Label
            });

            let tmpAudByCibles = [...groupeAudsForGraph.AudienceByCibles];
            tmpAudByCibles.reverse();
            for (let index = 0; index < tmpAudByCibles.length; index++) {
                if (dicoChildren[index]) continue;

                const element = tmpAudByCibles[index];
                let name = dicoLabels[index];
                let val = 0;
                
                if (!name)
                    name = element ? element.Name : '';
                
                if (element) {
                    val = element[grandeur];
                    if (!val)
                        val = 0;
                }
                tmpDataTab.push({
                    title: name,
                    label: val.toFixed(1).toString() + '%',
                    size: val,
                    color: theme.ColorGradient[index],
                    labelStyle: labelStyle,
                    dontRotateLabel: true,
                    children: []
                });

                tmpLegends.push({ title: name, color: theme.ColorGradient[index] });
            }
            setLegends(tmpLegends);
            setDataTab({ title: '', children: tmpDataTab, color: "#ffffff" });
        }
    }, [groupeAudsForGraph, theme]);

    React.useEffect(() => {
        if (groupeAuds.length > 0) {
            groupeAuds.forEach(groupeAud => {
                if (groupeAud.ID === props.questionId) {
                    setGroupeAudsForGraph(groupeAud);
                    return;
                }
            });
        }
    }, [groupeAuds]);

    React.useEffect(() => {
        if (hoveredCell != null) {
            let tmpDataTabChildren = dataTab?.children;

            if (tmpDataTabChildren) {
                tmpDataTabChildren.map((child, index) => {
                    /* modifier ca */
                    //child.color = child.color?.toString().slice(0, -2) + "FF";
                    child.opacity = 1;
                    if (hoveredCell !== false) {
                        if (child.label !== hoveredCell.label) 
                            child.opacity = 0.1;
                            //child.color = child.color?.toString().slice(0, -2) + "1A";
                    }
                    return child;
                });
            }
            setDataTab({ title: '', children: tmpDataTabChildren });
        }
    }, [hoveredCell])

    if (!dataTab) return (<div></div>);

    return (
        <div style={{ overflow: 'visible' }}>
            <div style={{ float: 'left' }}>
                <Sunburst
                    style={{ stroke: 'transparent', strokeWidth: '1px' }}
                    hideRootNode
                    height={props.height}
                    width={props.width}
                    margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
                    data={dataTab}
                    onValueMouseOver={v => {
                        setHoveredCell(v);
                    }}
                    onValueMouseOut={v => setHoveredCell(false)}
                >
                    {hoveredCell ?
                        <Hint value={{x:0, y:0}}>
                            <div style={{ display: 'flex', color: '#fff', background: '#000', alignItems: 'center', padding: '5px', width: '100px', fontSize: 11, transform: 'translate(-50px, -9px)', textAlign: "center"}}>
                                {hoveredCell.title + ': ' + hoveredCell.size?.toFixed(2) + '%'}
                            </div>
                        </Hint>
                    : null}
                    <LabelSeries data={[{ x: 0, y: 0, label: props.questionLabel, style: props.questionLabelStyle }]} />
                </Sunburst>
            </div>
            <div style={{ fontSize: '12px' }}>
                <DiscreteColorLegend
                    width={props.legendWidth}
                    items={legends}
                />
            </div>
        </div>
    );
}