import { Parution } from "../data/objects/Parution";

export const sortParutionsAsc = (parutions: Parution[]) => {
    parutions.sort((a, b) => {
        return (a.releaseDate.getTime() - b.releaseDate.getTime());
    });
}

export const countParutionsPerYear = (parutions: Parution[]): number => {
    let ret = 0;
    let allYears: number[] = [];

    parutions.forEach(parution => {
        allYears.push(parution.releaseDate.getFullYear());
    });

    if (allYears.length > 0) {
        allYears.sort((a, b) => {
            return (b - a);
        });

        let previousYear = allYears[0];

        parutions.forEach(parution => {
            if (previousYear === parution.releaseDate.getFullYear())
                ret++;
        });
    }

    return (ret);
}

export const getCurrentNextParution = (parutions: Parution[]): CurrentParutionInfos => {
    let nextParuDate: Date | undefined = undefined;
    let nextParuInDays = 0;
    let currentParuDate: Date | undefined = undefined;
    let today = new Date();
    let currentParuIdx = 0;

    for (; currentParuIdx < parutions.length; currentParuIdx++) {
        const element = parutions[currentParuIdx];
        let paruDate = element.releaseDate;

        if (today >= paruDate && ((parutions.length > currentParuIdx + 1 && parutions[currentParuIdx + 1].releaseDate > today) || parutions.length < currentParuIdx + 1)) {
            currentParuDate = element.releaseDate;
            nextParuInDays = diffDate(today, parutions[currentParuIdx + 1].releaseDate);
            nextParuDate = parutions[currentParuIdx + 1].releaseDate;
            break;
        }
    }

    return { nextParuInDays, currentParuDate, currentParuIdx, nextParuDate };
}

export class CurrentParutionInfos {
    public nextParuInDays: number = 0;
    public currentParuDate: Date | undefined = undefined;
    public currentParuIdx: number = 0;
    public nextParuDate: Date | undefined = undefined;
}

const diffDate = (d1: Date, d2: Date): number => {
    var WNbJours = d2.getTime() - d1.getTime();
    return (Math.ceil(WNbJours / (1000 * 60 * 60 * 24)));
}