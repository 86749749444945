import { GetSupportOJDMensuelApiResponse } from '../../ApiResponse/diffusions/GetSupportOJDMensuelApiResponse';
import { OJDMensuel } from '../../objects/OJDMensuel';
import { IResponseToItemMap } from '../IResponseToItemMap';

export class GetSupportOJDMensuelMap implements IResponseToItemMap<GetSupportOJDMensuelApiResponse, OJDMensuel> {
    toItem(apiRes: GetSupportOJDMensuelApiResponse): OJDMensuel {
        return (new OJDMensuel(
            apiRes.ID,
            apiRes.SupportID,
            apiRes.Year,
            apiRes.Month,
            apiRes.DiffusionPayee,
            apiRes.DiffusionTotale,
            apiRes.MiseEnDistribution
        ));
    }
}