import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../reducers/root.reducer';
import { ProvidersManager } from '../../data/ProvidersManager';
import { ApiProviderConstants } from '../../utility/Constants'
import { Offer } from '../../data/objects/Offer';
import { OfferAPIProvider } from '../../data/providers/OfferApiProvider';
import { GET_OFFERS, OfferActionTypes } from '../types/offer.types';
import { Support } from '../../data/objects/Support';

export const getOffers = (support: Support, supportCodeTsm: string): ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    const provider = ProvidersManager.Singleton().getProvider<OfferAPIProvider>(ApiProviderConstants.OfferApiProviderName);

    if (provider) {
        const getOfferCallback = (offers: Offer[] | undefined) => {
            if (offers)
                dispatch(getOffersFromApi(offers))
            else
                dispatch(getOffersFromApi([]))
        }
        provider.getOffersBySupportCodeTsm(supportCodeTsm).then(getOfferCallback);
    }
}

function getOffersFromApi(offers: Offer[]): OfferActionTypes {
    return ({
        type: GET_OFFERS,
        payload: offers
    })
}