import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GET_PARUTIONS, ParutionsActionTypes } from '../types/parution.types';
import { RootState } from '../reducers/root.reducer';
import { Parution } from '../../data/objects/Parution';
import { ProvidersManager } from '../../data/ProvidersManager';
import { ParutionAPIProvider } from '../../data/providers/ParutionAPIProvider';
import { ApiProviderConstants, ProductConstantsType, ProductConstants } from '../../utility/Constants'

export const getParutions = (product: ProductConstantsType, support: string) : ThunkAction<void, RootState, {}, AnyAction> => async dispatch => {
    const provider: ParutionAPIProvider | undefined = ProvidersManager.Singleton().getProvider<ParutionAPIProvider>(ApiProviderConstants.ParutionApiProviderName);

    if (provider) {
        const getParutionsCallback = (parutions: Parution[] | undefined) => {
            dispatch(getParutionsFromApi(parutions));
        }

        if (product === ProductConstants.Medialand)
            provider.getParutionsBySupportCodeTsm(support).then(getParutionsCallback);
        else
            provider.getParutionsAdwBySupportId(support).then(getParutionsCallback);
    }
}

function getParutionsFromApi(parutions: Parution[] | undefined) : ParutionsActionTypes {
    return {
        type: GET_PARUTIONS,
        payload: parutions
    };
}