import { GetOffersMLResponse } from '../../ApiResponse/offres/GetOffersMLResponse';
import { Offer } from '../../objects/Offer';
import { IResponseToItemMap } from '../IResponseToItemMap';

export class OffersMLMap implements IResponseToItemMap<GetOffersMLResponse, Offer> {
    public toItem(apiRes: GetOffersMLResponse): Offer {
        let ret = new Offer(
            "",
            apiRes.format,
            "",
            new Date(apiRes.debut),
            new Date(apiRes.fin),
            apiRes.price,
            apiRes.emplacement
        );

        return (ret);
    }
}