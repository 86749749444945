import { IAPIItem } from './IAPIItem';

export class OJDAnnuel implements IAPIItem {
    public ID: number;
    public SupportID: number;
    public Year: number;
    public DiffusionPayeeFrance: number;
    public DiffusionTotaleFrance: number;
    public Abonnements: number;
    public Ventes: number;
    public DiffusionIndividuelle: number;
    public MiseEnDistribution: number;
    public DistDFP: number;

    public constructor(ID: number, SupportID: number, Year: number, DiffusionPayeeFrance: number, DiffusionTotaleFrance: number, Abonnements: number, Ventes: number, DiffusionIndividuelle: number, MiseEnDistribution: number, DistDFP: number) {
        this.ID = ID;
        this.SupportID = SupportID;
        this.Year = Year;
        this.DiffusionPayeeFrance = DiffusionPayeeFrance;
        this.DiffusionTotaleFrance = DiffusionTotaleFrance;
        this.Abonnements = Abonnements;
        this.Ventes = Ventes;
        this.DiffusionIndividuelle = DiffusionIndividuelle;
        this.MiseEnDistribution = MiseEnDistribution;
        this.DistDFP = DistDFP;
    }
}