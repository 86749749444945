import { IResponseToItemMap } from '../IResponseToItemMap';
import { GetParutionsNodeResponse } from '../../ApiResponse/parution/GetParutionsNodeResponse';
import { Parution } from '../../objects/Parution';

export class ParutionsNodeMap implements IResponseToItemMap<GetParutionsNodeResponse, Parution> {
    public toItem(apiRes: GetParutionsNodeResponse): Parution {
        let res = new Parution(
            new Date(apiRes.releaseDate),
            new Date(apiRes.startDate),
            new Date(apiRes.endDate)
        );
        
        res.commercialDate = new Date(apiRes.commercialDate);
        res.technicalClosure = new Date(apiRes.technicalClosure);

        return (res);
    }
}